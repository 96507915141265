@charset "UTF-8";

/* color */

$main: #1D1D1F !default;
$primary: #fff !default;
$cat1: #005FFF !default;
$cat2: #FD2C24 !default;
$cat3: #00A446 !default;
$cat4: #FBC800 !default;
$cat5: #AF8359 !default;
$cat6: #D379B7 !default;
$cat7: #60AFBE !default;
//
$blue: #005FFF !default;
$red: #FD2C24 !default;
$green: #00A446 !default;
$yellow: #FBC800 !default;
$other: #DFDFDF !default;
$gray: #A4A4A4 !default;