@charset "UTF-8";
.g-header {
    max-width: none;
    padding: 0px;
    margin-left: auto;
    margin-right: auto;
    @include mq(sp) {
        // padding: 20px 20px;
        //padding: 0px;
    }
    &__outer {
        padding: 0px;
        box-sizing: border-box;
        background-color: #fff;
        position: relative;
        width: 100%;
        z-index: 200;
        margin-bottom: 0px;
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        max-width: 1440px;
        width: 1440px;
        transform-origin: left top;
        @include mq(sp) {
            //sp
            max-width: 375px;
            margin-bottom: 0px;
            position: fixed;
            left: 0px;
            top: 0px;
            width: 100%;
            z-index: 500;
            max-height: 70px;
            //box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        }
    }
    &__inner {
        box-sizing: border-box;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        position: relative;
        padding: 28px 50px 23px;
        min-height: 110px;
        box-sizing: border-box;
        max-width: 1300px;
        @include mq(sp) {
            //sp
            padding: 15px 25px;
            min-height: 70px;
        }
    }
}

.g-logo {
    &__outer {
        display: block;
    }
    &__inner {
        a {
            max-width: 308px;
            display: block;
            @include mq(sp) {
                max-width: 215px;
            }
        }
        h1,
        p {
            max-width: 308px;
            display: block;
            @include mq(sp) {
                max-width: 215px;
            }
            img {
                max-width: 308px;
                display: block;
                width: 100%;
                height: auto;
                @include mq(sp) {
                    max-width: 215px;
                }
            }
        }
    }
    display: inline-block;
    //max-width: 182px;
    max-width: 407px;
    @include mq(sp) {
        max-width: 131px;
    }
    a {
        display: block;
        text-decoration: none;
    }
}

.g-navi {
    &__outer {
        margin: 0px;
        padding: 0px;
        margin-left: auto;
        @media print {
            display: none;
        }
        @media screen and (max-width: 1200px) {
            //display: none;
        }
        @media screen and (max-width: 855px) {
            //display: none;
        }
        @include mq(sp) {
            //sp
            display: none;
        }
    }
    &__inner {
        nav {
            box-sizing: border-box;
            @include mq(sp) {
                position: relative;
            }
            ul {
                list-style: none;
                display: flex;
                margin-left: -23px;
                margin-right: -23px;
                width: calc(100% + 46px);
                li {
                    margin-left: 23px;
                    margin-right: 23px;
                    //border-left: solid 1px #ccc;
                    position: relative;
                    color: $main;
                    a {
                        //pointer-events: all;
                        display: block;
                        //font-size: $font-size-18;
                        font-size: $font-size-16;
                        line-height: 1.1em;
                        //letter-spacing: 0.00em;
                        font-weight: $font-normal;
                        color: $main;
                        padding: 5px 0px;
                        //min-width: 220px;
                        text-align: center;
                        width: 100%;
                        box-sizing: border-box;
                        text-decoration: none;
                        transition: 0.22s cubic-bezier(0.44, 0.18, 0.49, 0.99);
                        white-space: nowrap;
                        font-weight: $font-bold;
                        @include mq(sp) {
                            font-size: $font-size-18;
                        }
                        position: relative;
                        overflow: hidden;
                        &.current,
                        &:hover {
                            text-decoration: none;
                            opacity: 0.75;
                            color: #666;
                            &:before {
                                //s transform-origin: left top;
                                opacity: 1;
                                transform: scale(1, 1);
                                transform-origin: left top;
                            }
                        }
                        &:before {
                            content: "";
                            display: block;
                            width: 100%;
                            height: 0px;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            //border-bottom: 2px solid $primary;
                            border-bottom: 1px solid $primary;
                            //transform-origin: left top;
                            transition: transform 0.3s;
                            //transition-delay: 0.2s;
                            transform: scale(0, 1);
                            transform-origin: right top;
                        }
                    }
                }
            }
        }
    }
}

.g-mn {
    &__outer {
        position: absolute;
        width: 45px;
        height: 45px;
        right: 0px;
        top: 0px;
        //position: relative;
        display: none;
        width: auto;
        z-index: 110;
        @include mq(sp) {
            //width: 113px;
            display: block;
            width: 45px;
            height: 45px;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &__inner {
        display: block;
        position: relative;
        @include mq(sp) {
            width: 45px;
            height: 45px;
        }
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            transition: 0s;
            text-decoration: none;
            width: 45px;
            height: 45px;
            position: relative;
            &:hover {
                opacity: 1;
            }
            &.open {
                //background-color: #000;
                .g-mn {
                    &-bar {
                        width: 25px;
                        height: 3px;
                        background-color: transparent;
                        &:before {
                            content: "";
                            width: 25px;
                            height: 3px;
                            background-color: #646464;
                            opacity: 1;
                            position: absolute;
                            left: 0px;
                            top: 0px;
                            transform: rotate(-45deg);
                            @include mq(sp) {}
                        }
                        &:after {
                            content: "";
                            width: 25px;
                            height: 3px;
                            background-color: #646464;
                            opacity: 1;
                            position: absolute;
                            left: 0px;
                            bottom: 0px;
                            transform: rotate(45deg);
                            @include mq(sp) {}
                        }
                    }
                }
            }
        }
    }
    &-text {
        font-size: $font-size-15;
        line-height: 1.1em;
        margin-right: 13px;
        font-weight: 600;
        font-style: normal;
    }
    &-bar {
        position: relative;
        //right: 0px;
        //top: 0px;
        // transform: translateX(-50%) translateY(-50%);
        width: 25px;
        height: 3px;
        //background-color: transparent;
        border-radius: 0px;
        display: block;
        @include mq(sp) {
            width: 25px;
            height: 3px;
            background-color: #646464;
            transition: 0.22s cubic-bezier(0.44, 0.18, 0.49, 0.99);
        }
        &:before {
            content: "";
            width: 25px;
            height: 3px;
            background-color: #646464;
            opacity: 1;
            position: absolute;
            left: 0px;
            top: -10px;
            transform: rotate(0deg);
            transition: 0.22s cubic-bezier(0.44, 0.18, 0.49, 0.99);
            @include mq(sp) {}
        }
        &:after {
            content: "";
            width: 25px;
            height: 3px;
            background-color: #646464;
            opacity: 1;
            position: absolute;
            left: 0px;
            bottom: -10px;
            transform: rotate(0deg);
            transition: 0.22s cubic-bezier(0.44, 0.18, 0.49, 0.99);
            @include mq(sp) {}
        }
    }
}

.g-navi-sp {
    width: 100%;
    height: 100vh;
    z-index: 2000;
    display: block;
    overflow: hidden;
    background-color: #fff;
    background-image: url('../img/common/sp_navi_bg.svg');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    z-index: 4290;
    position: absolute;
    right: 0px;
    top: 0px;
    &.open {
        &:before {
            transform: scale(1);
            opacity: 1;
        }
        .g-navi {
            &__outer {
                display: block;
                &.open {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }
    }
    @include mq(sp) {
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0px;
        top: 0px;
        //height: 100vh;
        //height: 100dvh;
    }
    &__outer {
        display: none;
        max-width: 1440px;
        position: fixed;
        right: 0px;
        top: 0px;
        width: 100%;
        height: 100vh;
        z-index: 460;
        @include mq(sp) {
            //sp
            //max-width: 375px;
            top: 70px;
            width: 375px;
            height: 100%;
            height: calc(100vh - 100px);
            transform-origin: left top;
            //background-color: aqua;
        }
        .g-mn {
            &__outer {
                position: absolute;
                right: 16px;
                top: 16px;
                display: block;
                z-index: 110;
                pointer-events: auto;
                width: 45px;
                height: 12px;
                background-color: transparent;
                z-index: 1002;
                @include mq(sp) {
                    display: none;
                    width: 45px;
                    height: 45px;
                    right: 25px;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: #a195b5;
                }
            }
            &__inner {
                display: inline-block;
                width: 45px;
                height: 12px;
                @include mq(sp) {
                    display: flex;
                    position: relative;
                    width: 45px;
                    height: 45px;
                }
                a {
                    @include mq(sp) {
                        display: flex;
                        align-items: center;
                        transition: 0s;
                        text-decoration: none;
                        color: #fff;
                        width: 45px;
                        height: 45px;
                    }
                }
            }
            &-text {
                display: none;
                @include mq(sp) {
                    font-size: $font-size-15;
                    line-height: 1.1em;
                    font-weight: bold;
                    margin-right: 13px;
                    display: inline-block;
                }
            }
            &-bar {
                position: absolute;
                left: 0;
                top: 0;
                transform: translateX(0%) translateY(0%);
                width: 45px;
                height: 12px;
                background-color: transparent;
                display: block;
                @include mq(sp) {
                    //right: 50%;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
                &:before {
                    content: "";
                    width: 45px;
                    height: 1px;
                    border-radius: 0px;
                    //background-color: #fff;
                    background-color: #fff;
                    opacity: 0.6;
                    position: absolute;
                    left: 0px;
                    top: 50%;
                    transform: rotate(15deg);
                    @include mq(sp) {
                        left: -2px;
                    }
                }
                &:after {
                    content: "";
                    width: 45px;
                    height: 1px;
                    border-radius: 0px;
                    //background-color: #fff;
                    background-color: #fff;
                    opacity: 0.6;
                    position: absolute;
                    left: 0px;
                    top: 50%;
                    transform: rotate(-15deg);
                    @include mq(sp) {
                        left: -2px;
                    }
                    //transition: 0.15s cubic-bezier(0.44, 0.18, 0.49, 0.99);
                }
            }
            a {
                position: absolute;
                right: 0;
                top: 0;
                display: block;
                width: 45px;
                height: 12px;
                border: none;
                transition: 0s;
                &:hover {
                    opacity: 1;
                }
            }
        }
        padding-top: 0px;
        box-sizing: border-box;
        @include mq(sp) {
            width: 100%;
        }
    }
    &__inner {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        //min-height: 100vh;
        //min-height: 100dvh;
        height: 100%;
        .g-navi {
            &__outer {
                display: block;
                padding: 0;
                margin: 0;
                padding-top: 0px;
                position: relative;
                opacity: 0;
                transform: translateX(10px);
                transition: all 0.33s cubic-bezier(0.44, 0.18, 0.49, 0.99);
                position: absolute;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100dvh;
                //height: calc(100vh - 220px);
                //height: calc(100dvh - 220px);
                z-index: 80;
            }
            &__inner {
                display: block;
                padding: 45px 80px;
                padding-top: 65px;
                padding-bottom: 60px;
                margin: 0;
                position: absolute;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                @include mq(sp) {
                    padding: 45px 80px;
                    padding-top: 65px;
                    padding-bottom: 60px;
                }
                @media screen and (max-width: 280px) {
                    padding-top: 85px;
                }
                nav {
                    padding: 0px;
                    margin: 0;
                    //overflow: scroll;
                    //max-height: 100vh;
                    //min-height: 100vh;
                }
                ul {
                    display: block;
                    margin: 0;
                    width: 100%;
                    li {
                        display: block;
                        padding: 0;
                        border-bottom: none;
                        position: relative;
                        margin: 0px;
                        margin-bottom: 28px;
                        a {
                            color: #fff;
                            padding: 15px 0px;
                            text-align: left;
                            font-size: $font-size-18;
                            box-sizing: border-box;
                            position: relative;
                            @media screen and (max-width: 280px) {
                                padding: 10px 0px;
                            }
                            &::before,
                            &::after {
                                display: none;
                            }
                            &:hover {
                                text-decoration: none;
                                opacity: .7;
                                &:before {
                                    display: none;
                                }
                                @include mq(sp) {
                                    opacity: 1;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}