@charset "UTF-8";
.g-footer {
    &__outer {
        padding-top: 0px;
        position: relative;
        @include mq(sp) {
            //sp
            padding-top: 0px;
        }
    }
    &__inner {
        max-width: 100%;
        padding: 0px;
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        //padding-top: 150px;
        padding-top: 10px;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 10px;
        z-index: 6;
        display: flex;
        align-items: center;
        max-width: 1300px;
        @include mq(sp) {
            padding-top: 50px;
            padding-left: 0px;
            padding-right: 0px;
            flex-direction: column;
        }
        &:first-child {
            padding-top: 75px;
            padding-bottom: 0;
            display: block;
            position: relative;
            z-index: 5;
            max-width: 100%;
            @include mq(sp) {
                //padding: 25px;
                padding-top: 0px;
                //background-color: $yellow;
            }
            &:before {
                content: "";
                display: block;
                width: 50%;
                height: 100%;
                position: absolute;
                left: 0px;
                top: 0px;
                background-image: url(../img/common/ft_bg_l.svg);
                background-position: right bottom;
                z-index: 3;
                background-color: $yellow;
                @include mq(sp) {
                    width: 100%;
                    display: none;
                }
            }
            &:after {
                content: "";
                display: block;
                width: 50%;
                height: 100%;
                position: absolute;
                right: 0px;
                top: 0px;
                background-image: url(../img/common/ft_bg_r.svg);
                background-position: left top -920px;
                z-index: 4;
                @include mq(sp) {
                    width: 100%;
                    display: none;
                }
            }
        }
        .g-logo {
            &__outer {
                display: flex;
                align-items: baseline;
                max-width: 100%;
                width: 100%;
                margin-bottom: 48px;
                @include mq(sp) {
                    margin-bottom: 35px;
                }
                img {
                    max-width: 362px;
                    display: block;
                    width: 100%;
                    height: auto;
                    @include mq(sp) {
                        max-width: 227px;
                    }
                }
                /*@media screen and (max-width: 920px) {
                    img {
                        //max-width: 169px;
                        width: 100%;
                        position: relative;
                        top: -1px;
                        //display: none;
                    }
                }
                @media screen and (max-width: 920px) {
                    max-width: 362px;
                    margin-left: auto;
                    margin-right: auto;
                    img {
                        //max-width: 123px;
                        position: relative;
                        //top: -1px;
                        //display: none;
                    }
                }*/
            }
            &__inner {
                max-width: 362px;
                @include mq(sp) {
                    max-width: 227px;
                }
                a {
                    text-decoration: none;
                    color: #333;
                    display: block;
                    max-width: 362px;
                    @include mq(sp) {
                        max-width: 227px;
                    }
                }
                h1,
                p {
                    display: block;
                    max-width: 362px;
                    @include mq(sp) {
                        max-width: 227px;
                    }
                }
            }
            display: inline-block;
            //max-width: 182px;
            max-width: 362px;
            @include mq(sp) {
                max-width: 227px;
            }
        }
        .g-marks {
            &__outer {
                display: block;
                margin-left: 0px;
                margin-right: auto;
                @include mq(sp) {
                    margin-left: auto;
                    margin-bottom: 50px;
                }
            }
            &__inner {
                ul {
                    list-style: none;
                    display: flex;
                    align-items: center;
                    li {
                        margin-right: 30px;
                        @include mq(sp) {
                            margin-left: 15px;
                            margin-right: 15px;
                        }
                        &.l-certified_b {
                            max-width: 50px;
                        }
                        &.l-j_starup {
                            max-width: 99px;
                        }
                        &.l-pmark {
                            max-width: 79px;
                        }
                    }
                }
            }
        }
        .g-navi {
            list-style: none;
            display: flex;
            /*@media screen and (max-width: 920px) {
                flex-direction: column;
                justify-content: center;
            }*/
            @include mq(sp) {
                flex-direction: column;
                padding-left: 25px;
            }
            li {
                a {
                    text-decoration: none;
                    font-size: $font-size-16;
                    display: block;
                    @include mq(sp) {
                        font-size: $font-size-15;
                    }
                }
                padding-left: 50px;
                /*@media screen and (max-width: 920px) {
                    padding-right: 0;
                    padding-bottom: 23px;
                    text-align: center;
                }*/
                @include mq(sp) {
                    text-align: left;
                    padding-left: 0px;
                    padding-bottom: 20px;
                }
            }
            &__outer {
                display: block;
                margin-left: auto;
                margin-right: 0px;
                padding-top: 10px;
                @include mq(sp) {
                    margin-left: 0px;
                    margin-right: auto;
                    padding-bottom: 30px;
                }
            }
            &__inner {}
        }
    }
    &-row {
        display: flex;
        padding: 0px 50px 0px;
        min-height: 110px;
        box-sizing: border-box;
        max-width: 1300px;
        position: relative;
        z-index: 7;
        margin-left: auto;
        margin-right: auto;
        @include mq(sp) {
            flex-direction: row;
            padding: 0;
            flex-direction: column;
            padding: 0px 0px;
        }
    }
    &-col {
        width: 50%;
        display: flex;
        position: relative;
        flex-direction: column;
        @include mq(sp) {
            width: 100%;
            box-sizing: border-box;
            &:first-child {
                padding: 20px;
                padding-bottom: 30px;
                background-color: $yellow;
                box-sizing: border-box;
                padding-top: 55px;
            }
            &:last-child {
                padding: 20px;
                background-image: url('../img/common/sp_ft_navi_bg.svg');
                background-repeat: no-repeat;
                background-position: right bottom;
                background-color: #005FFF;
                background-size: 100% auto;
            }
        }
        //background-color:#eee;
        &:last-child {
            //flex-direction: column;
            // background-color: #C9C9C9;
        }
    }
}

.g-main-navi {
    ul {
        //display: flex;
        //justify-content: center;
        display: flex;
        list-style: none;
        justify-content: center;
        li {
            display: inline-block;
            list-style: none;
            padding-left: 10px;
            padding-right: 10px;
            position: relative;
            font-size: $font-size-14;
            /* @media screen and (max-width: 920px) {
                //tablet
                font-size: $font-size-14;
                padding-left: 6px;
                padding-right: 6px;
                margin-bottom: 5px;
            }*/
            @include mq(sp) {
                font-size: $font-size-14;
                padding-left: 6px;
                padding-right: 6px;
                margin-bottom: 5px;
            }
            a {
                color: #333333;
                text-decoration: none;
                //font-size: 18px;
                font-weight: 500;
                font-size: $font-size-14;
                position: relative;
                display: block;
                padding-bottom: 3px;
                @include mq(sp) {
                    font-size: $font-size-14;
                }
                position: relative;
                overflow: hidden;
                &:hover {
                    text-decoration: none;
                    opacity: 1;
                    &:before {
                        opacity: 1;
                        transform: translateX(0px);
                    }
                    &:after {
                        transform: translateX(100%);
                    }
                }
                &:before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 0px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    //border-bottom: 2px solid $primary;
                    border-bottom: 1px solid #C9C9C9;
                    transition: 0.3s;
                    transition-delay: 0.2s;
                    transform: translateX(-100%);
                    /*
                    @media screen and (max-width: 920px) {
                        //tablet
                        transform: translateX(0px);
                    }*/
                    @include mq(sp) {
                        transform: translateX(0px);
                    }
                }
                &:after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 0px;
                    position: absolute;
                    left: 0px;
                    bottom: 0;
                    //border-bottom: 2px solid $primary;
                    border-bottom: 1px solid #C9C9C9;
                    transition: 0.2s;
                    transform: translateX(0px);
                    @include mq(sp) {
                        transform: translateX(0px);
                    }
                }
            }
        }
    }
    &__outer {
        text-align: center;
        margin: 0px;
        padding: 0px;
        margin-bottom: 177px;
        @include mq(sp) {
            margin: 0px;
            padding: 0px;
            display: none;
        }
    }
}

.g-copyright {
    p {
        font-size: $font-size-11;
        line-height: 1.6em;
        text-align: center;
        /*
        @media screen and (max-width: 920px) {
            //tablet
        }*/
        @include mq(sp) {
            text-align: center;
            font-size: $font-size-9;
            letter-spacing: 0.025em;
            transform-origin: left top;
            transform: scale(0.9);
            white-space: nowrap;
        }
    }
    &__outer {
        margin: 0;
        padding: 0 0;
        padding-bottom: 0px;
        margin-left: auto;
        /*
        @media screen and (max-width: 920px) {
            //tablet
            padding: 0px;
            text-align: center;
            margin-bottom: 20px;
            margin-right: auto;
        }*/
        @include mq(sp) {
            padding: 0px;
            text-align: center;
            margin-bottom: 20px;
            margin-right: auto;
        }
    }
}

.g-brand-navi {
    list-style: none;
    margin: 0;
    padding: 0;
    @include mq(sp) {
        //display: none;
        margin-bottom: 0px;
    }
    ul {
        display: block;
        margin: 0;
        padding: 0;
        font-size: $font-size-15;
        line-height: 1.1em;
        /*@media screen and (max-width: 920px) {
            //tablet
            width: 100%;
            flex-direction: column;
            justify-content: center;
        }
        @include mq(sp) {
            width: 100%;
            flex-direction: column;
            justify-content: center;
        }*/
        li {
            // display: inline-block;
            position: relative;
            margin: 0;
            padding: 0;
            font-size: $font-size-15;
            line-height: 1.1em;
            margin-left: 0px;
            margin-bottom: 30px;
            list-style: none;
            a {
                &:before {
                    content: ">";
                    margin-right: 5px;
                }
            }
            ul {
                margin-top: 30px;
                margin-left: 1em;
                @include mq(sp) {
                    margin-top: 20px;
                }
                li {
                    a {
                        &:before {
                            content: "-";
                            margin-right: 5px;
                        }
                    }
                }
            }
            /*
            @media screen and (max-width: 920px) {
                //tablet
                text-align: center;
                margin: 0;
                margin-bottom: 30px;
            }*/
            @include mq(sp) {
                font-size: $font-size-13;
                text-align: left;
                margin: 0;
                margin-bottom: 20px;
            }
            a {
                //color: ;
                text-decoration: none;
                font-size: $font-size-14;
                line-height: 1.1em;
                text-decoration: none;
                @include mq(sp) {
                    font-size: $font-size-13;
                }
                &:hover {
                    text-decoration: none;
                    color: $primary;
                    opacity: 1;
                }
            }
        }
    }
    &__outer {
        margin: 0;
        padding: 0;
        margin-left: 0px;
        margin-bottom: 0px;
        padding-left: 100px;
        padding-bottom: 60px;
        @include mq(sp) {
            padding: 0;
        }
        p {
            font-size: $font-size-20;
            font-weight: bold;
            margin-bottom: 30px;
            @include mq(sp) {
                margin-bottom: 20px;
            }
        }
    }
}

.g-contact-navi {
    list-style: none;
    margin: 0;
    padding: 0;
    @include mq(sp) {
        //display: none;
        margin-bottom: 0px;
    }
    ul {
        display: block;
        margin: 0;
        padding: 0;
        font-size: $font-size-15;
        line-height: 1.1em;
        /*@media screen and (max-width: 920px) {
            //tablet
            width: 100%;
            flex-direction: column;
            justify-content: center;
        }
        @include mq(sp) {
            width: 100%;
            flex-direction: column;
            justify-content: center;
        }*/
        li {
            // display: inline-block;
            position: relative;
            margin: 0;
            padding: 0;
            font-size: $font-size-15;
            line-height: 1.1em;
            margin-left: 0px;
            margin-bottom: 30px;
            list-style: none;
            a {
                &:before {
                    content: ">";
                    margin-right: 5px;
                }
            }
            ul {
                margin-top: 30px;
                margin-left: 1em;
                @include mq(sp) {
                    margin-top: 20px;
                }
                li {
                    a {
                        &:before {
                            content: "-";
                            margin-right: 5px;
                        }
                    }
                }
            }
            @media screen and (max-width: 920px) {
                //tablet
                text-align: center;
                margin: 0;
                margin-bottom: 30px;
            }
            @include mq(sp) {
                font-size: $font-size-13;
                text-align: left;
                margin: 0;
                margin-bottom: 20px;
            }
            a {
                //color: ;
                text-decoration: none;
                font-size: $font-size-14;
                line-height: 1.1em;
                text-decoration: none;
                @include mq(sp) {
                    font-size: $font-size-13;
                }
                &:hover {
                    text-decoration: none;
                    color: $primary;
                    opacity: 1;
                }
            }
        }
    }
    &__outer {
        margin: 0;
        padding: 0;
        margin-left: 0px;
        margin-bottom: 0px;
        padding-left: 100px;
        padding-bottom: 60px;
        @include mq(sp) {
            padding: 0;
        }
        p {
            font-size: $font-size-20;
            font-weight: bold;
            margin-bottom: 30px;
            @include mq(sp) {
                margin-bottom: 20px;
            }
        }
    }
}

.g-service-navi {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    margin-left: 10px;
    @include mq(sp) {
        margin-left: 0;
    }
    p {
        font-size: $font-size-15;
        font-weight: bold;
        margin-bottom: 20px;
        color: $primary;
    }
    @include mq(sp) {
        //display: none;
        margin-bottom: 0px;
    }
    ul {
        display: block;
        margin: 0;
        padding: 0;
        font-size: $font-size-15;
        line-height: 1.1em;
        margin-bottom: 70px;
        li {
            // display: inline-block;
            position: relative;
            margin: 0;
            padding: 0;
            font-size: $font-size-15;
            line-height: 1.1em;
            margin-left: 0px;
            margin-bottom: 30px;
            list-style: none;
            color: $primary;
            a {
                &:before {
                    content: ">";
                    margin-right: 5px;
                }
                &:hover {
                    color: $main;
                }
            }
            ul {
                margin-top: 30px;
                margin-left: 1.5em;
                li {
                    color: $primary;
                    a {
                        &:before {
                            content: "-";
                            margin-right: 5px;
                        }
                        &:hover {
                            color: $main;
                        }
                    }
                }
            }
            /*
            @media screen and (max-width: 920px) {
                //tablet
                text-align: center;
                margin: 0;
                margin-bottom: 30px;
            }*/
            @include mq(sp) {
                font-size: $font-size-13;
                text-align: left;
                margin: 0;
                margin-bottom: 30px;
            }
            a {
                //color: ;
                text-decoration: none;
                font-size: $font-size-14;
                line-height: 1.1em;
                text-decoration: none;
                color: $primary;
                @include mq(sp) {
                    font-size: $font-size-13;
                    br {
                        display: none;
                    }
                }
                &:hover {
                    text-decoration: none;
                    color: $main;
                    opacity: 1;
                }
            }
        }
    }
    &__outer {
        margin: 0;
        padding: 0;
        margin-left: 0px;
        margin-bottom: 0px;
        padding-left: 170px;
        padding-top: 118px;
        padding-bottom: 40px;
        @include mq(sp) {
            padding-top: 40px;
            padding-left: 0px;
        }
        &>p {
            font-size: $font-size-20;
            font-weight: bold;
            margin-bottom: 30px;
            color: $primary;
        }
    }
}

.g-other-navi {
    list-style: none;
    margin: 0;
    padding: 0;
    @include mq(sp) {
        //display: none;
        margin-bottom: 0px;
    }
    ul {
        display: flex;
        margin: 0;
        padding: 0;
        font-size: $font-size-13;
        line-height: 1.1em;
        /*
        @media screen and (max-width: 920px) {
            //tablet
            width: 100%;
            flex-direction: column;
            justify-content: center;
        }*/
        @include mq(sp) {
            width: 100%;
            flex-direction: column;
            justify-content: center;
        }
        li {
            // display: inline-block;
            position: relative;
            margin: 0;
            padding: 0;
            font-size: $font-size-14;
            line-height: 1.1em;
            margin-left: 30px;
            list-style: none;
            /*
            @media screen and (max-width: 920px) {
                //tablet
                text-align: center;
                margin: 0;
                margin-bottom: 30px;
            }*/
            @include mq(sp) {
                font-size: $font-size-13;
                text-align: center;
                margin: 0;
                margin-bottom: 30px;
            }
            a {
                //color: ;
                text-decoration: none;
                font-size: $font-size-14;
                line-height: 1.1em;
                text-decoration: none;
                @include mq(sp) {
                    font-size: $font-size-13;
                }
                &:hover {
                    text-decoration: none;
                    color: $primary;
                    opacity: 1;
                }
            }
        }
    }
    &__outer {
        margin: 0;
        padding: 0;
        margin-left: auto;
        margin-bottom: 30px;
        /*
        @media screen and (max-width: 920px) {
            //tablet
            position: static;
            padding: 0px;
            margin-bottom: 35px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }*/
        @include mq(sp) {
            position: static;
            padding: 0px;
            margin-bottom: 35px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }
}

.g-follow {
    &__outer {
        margin: 0;
        padding: 0;
        margin-left: 0px;
        margin-bottom: 0px;
        padding-left: 100px;
        padding-bottom: 130px;
        @include mq(sp) {
            padding-top: 20px;
            padding-left: 0px;
            padding-bottom: 0px;
        }
        p {
            font-size: $font-size-20;
            font-weight: bold;
            margin-bottom: 30px;
            @include mq(sp) {
                margin-bottom: 20px;
            }
        }
    }
    &__inner {}
    &-navi {
        &>ul {
            display: block;
            margin: 0;
            padding: 0;
            font-size: $font-size-13;
            line-height: 1.1em;
            li {
                position: relative;
                margin: 0;
                padding: 0;
                font-size: $font-size-15;
                line-height: 1.1em;
                margin-left: 0px;
                margin-bottom: 30px;
                list-style: none;
                a {
                    &:before {
                        content: ">";
                        margin-right: 5px;
                    }
                }
                ul {
                    margin-top: 30px;
                    margin-left: 1em;
                    li {
                        a {
                            &:before {
                                content: "-";
                                margin-right: 5px;
                            }
                        }
                    }
                }
                /*@media screen and (max-width: 920px) {
                    //tablet
                    text-align: center;
                    margin: 0;
                    margin-bottom: 30px;
                }*/
                @include mq(sp) {
                    font-size: $font-size-13;
                    text-align: left;
                    margin: 0;
                    margin-bottom: 30px;
                }
                a {
                    //color: ;
                    text-decoration: none;
                    font-size: $font-size-14;
                    line-height: 1.1em;
                    text-decoration: none;
                    @include mq(sp) {
                        font-size: $font-size-13;
                    }
                    &:hover {
                        text-decoration: none;
                        color: $primary;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.g-sns {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    li {
        margin-left: 0px;
        margin-right: 25px;
        &:last-child {
            margin-right: 0;
        }
        &:before {
            display: none;
        }
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            //height: 32px;
            i {
                svg {
                    transition: 0.22s cubic-bezier(0.44, 0.18, 0.49, 0.99);
                    path {
                        transition: 0.22s cubic-bezier(0.44, 0.18, 0.49, 0.99);
                    }
                }
            }
            &.ico_x_link {
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.ico_facebook_link {
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.ico_youtube_link {
                width: 37px;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.ico_note_link {
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &:hover {
                opacity: 0.75;
                fill: $primary;
                i {
                    svg {
                        path {
                            fill: $primary;
                        }
                    }
                }
            }
        }
    }
    &__outer {
        margin-left: auto;
        margin-bottom: 0px;
        /*@media screen and (max-width: 920px) {
            //tablet
            display: block;
            margin-right: auto;
            margin-bottom: 45px;
        }*/
        @include mq(sp) {
            display: block;
            margin-bottom: 45px;
        }
    }
    &__inner {
        padding-right: 5px;
    }
}

.g-pagetop {
    display: flex;
    align-items: center;
    font-size: $font-size-14;
    //font-weight: $font-medium;
    line-height: 1.1em;
    color: $main;
    position: relative;
    text-decoration: none;
    font-family: europa, sans-serif;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.1em;
    /*@media screen and (max-width: 560px) {
        font-size: $font-size-12;
    }*/
    &:hover {
        color: $main;
    }
    .ico_pagetop {
        margin-left: 15px;
        @include mq(sp) {
            margin-left: 10px;
        }
        //margin-bottom: 2px;
    }
    &__outer {
        display: block;
        margin-left: auto;
        /*@media screen and (max-width: 768px) {
            position: absolute;
            right: 0px;
            top: -56px;
        }*/
    }
}