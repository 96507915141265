@charset "UTF-8";

/* size */

$font-size-110: 6.875rem !default;
$font-size-80: 5rem !default;
$font-size-75: 4.687rem !default;
$font-size-72: 4.5rem !default;
$font-size-65: 4.642rem !default;
$font-size-64: 4rem !default;
$font-size-60: 3.75rem !default;
$font-size-55: 3.437rem !default;
$font-size-50: 3.125rem !default;
$font-size-48: 3rem !default;
$font-size-46: 2.875rem !default;
$font-size-42: 2.625rem !default;
$font-size-41: 2.562rem !default;
$font-size-40: 2.5rem !default;
$font-size-39: 2.437rem !default;
$font-size-38: 2.375rem !default;
$font-size-37: 2.312rem !default;
$font-size-36: 2.25rem !default;
$font-size-35: 2.187rem !default;
$font-size-33: 2.062rem !default;
$font-size-32: 2rem !default;
$font-size-30: 1.875rem !default;
$font-size-29: 1.812rem !default;
$font-size-28: 1.75rem !default;
$font-size-27: 1.687rem !default;
$font-size-26: 1.625rem !default;
$font-size-25: 1.562rem !default;
$font-size-24: 1.5rem !default;
$font-size-23: 1.437rem !default;
$font-size-22: 1.375rem !default;
$font-size-21: 1.312rem !default;
$font-size-20: 1.25rem !default;
$font-size-19: 1.187rem !default;
$font-size-18: 1.125rem !default;
$font-size-17: 1.062rem !default;
$font-size-16: 1rem !default;
$font-size-15: 0.937rem !default;
$font-size-14: 0.875rem !default;
$font-size-13: 0.812rem !default;
$font-size-12: 0.75rem !default;
$font-size-11: 0.687rem !default;
$font-size-10: 0.625rem !default;
$font-size-9: 0.562rem !default;
$font-size-8: 0.5rem !default;

/* weight */

$font-bold: 700 !default;
$font-medium: 500 !default;
$font-normal: 400 !default;
$font-thin: 300 !default;