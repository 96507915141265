@charset "UTF-8";
.ico_facebook {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    svg {
        display: block;
        width: 32px;
        height: 32px;
    }
    /*&:before {
        content: "";
        display: block;
        width: 32px;
        height: 32px;
        background-image: url(../img/common/ico_facebook.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }*/
}

.ico_x {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    svg {
        display: block;
        width: 32px;
        height: 32px;
    }
    /*&:before {
        content: "";
        display: block;
        width: 32px;
        height: 32px;
        background-image: url(../img/common/ico_x.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }*/
}

.ico_youtube {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
    position: relative;
    svg {
        display: block;
        width: 37px;
        height: 37px;
        position: absolute;
        left: 0px;
        top: 0px;
    }
    /*&:before {
        content: "";
        display: block;
        width: 32px;
        height: 32px;
        background-image: url(../img/common/ico_youtube.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }*/
}

.ico_note {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    position: relative;
    svg {
        display: block;
        width: 32px;
        height: 32px;
        position: absolute;
        left: 2px;
        top: 1px;
    }
    /*&:before {
        content: "";
        display: block;
        width: 23px;
        height: 23px;
        background-image: url(../img/common/ico_note.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }*/
}

.ico_link_copy {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    &:before {
        content: "";
        display: block;
        width: 40px;
        height: 40px;
        background-image: url(../img/common/ico_link.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_insta {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    &:before {
        content: "";
        display: block;
        width: 32px;
        height: 32px;
        background-image: url(../img/common/ico_insta.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_plus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    position: relative;
    &:before {
        content: "";
        display: block;
        width: 11px;
        height: 2px;
        background-color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    &:after {
        content: "";
        display: block;
        width: 2px;
        height: 11px;
        background-color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.ico_more {
    font-style: normal;
    &:before {
        content: "→";
    }
}

.ico_toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    @include mq(sp) {
        width: 29px;
        height: 29px;
    }
    &:before {
        content: "";
        display: block;
        width: 38px;
        height: 38px;
        @include mq(sp) {
            width: 29px;
            height: 29px;
        }
        background-image: url(../img/common/ico_toggle.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_cat1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    @include mq(sp) {
        width: 32px;
        height: 32px;
    }
    &:before {
        content: "";
        display: block;
        width: 44px;
        height: 44px;
        @include mq(sp) {
            width: 32px;
            height: 32px;
        }
        background-image: url(../img/common/ico_cat1.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_cat2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    @include mq(sp) {
        width: 32px;
        height: 32px;
    }
    &:before {
        content: "";
        display: block;
        width: 44px;
        height: 44px;
        @include mq(sp) {
            width: 32px;
            height: 32px;
        }
        background-image: url(../img/common/ico_cat2.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_cat3 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    @include mq(sp) {
        width: 32px;
        height: 32px;
    }
    &:before {
        content: "";
        display: block;
        width: 44px;
        height: 44px;
        @include mq(sp) {
            width: 32px;
            height: 32px;
        }
        background-image: url(../img/common/ico_cat3.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_cat4 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    @include mq(sp) {
        width: 32px;
        height: 32px;
    }
    &:before {
        content: "";
        display: block;
        width: 44px;
        height: 44px;
        @include mq(sp) {
            width: 32px;
            height: 32px;
        }
        background-image: url(../img/common/ico_cat4.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_cat5 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    @include mq(sp) {
        width: 32px;
        height: 32px;
    }
    &:before {
        content: "";
        display: block;
        width: 44px;
        height: 44px;
        @include mq(sp) {
            width: 32px;
            height: 32px;
        }
        background-image: url(../img/common/ico_cat5.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_cat6 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    @include mq(sp) {
        width: 32px;
        height: 32px;
    }
    &:before {
        content: "";
        display: block;
        width: 44px;
        height: 44px;
        @include mq(sp) {
            width: 32px;
            height: 32px;
        }
        background-image: url(../img/common/ico_cat6.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_cat7 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    @include mq(sp) {
        width: 32px;
        height: 32px;
    }
    &:before {
        content: "";
        display: block;
        width: 44px;
        height: 44px;
        @include mq(sp) {
            width: 32px;
            height: 32px;
        }
        background-image: url(../img/common/ico_cat7.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_cat8 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    @include mq(sp) {
        width: 32px;
        height: 32px;
    }
    &:before {
        content: "";
        display: block;
        width: 44px;
        height: 44px;
        @include mq(sp) {
            width: 32px;
            height: 32px;
        }
        background-image: url(../img/common/ico_cat8.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_search {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    height: 23px;
    &:before {
        content: "";
        display: block;
        width: 23px;
        height: 23px;
        background-image: url(../img/common/ico_search.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_search_w {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    height: 23px;
    &:before {
        content: "";
        display: block;
        width: 23px;
        height: 23px;
        background-image: url(../img/common/ico_search_w.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_prev {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    transform: rotate(180deg);
    svg {
        display: block;
        width: 11px;
        height: 16px;
        fill: #A4A4A4;
        margin-right: -4px;
        transition: 0.00s cubic-bezier(0.44, 0.18, 0.49, 0.99);
    }
}

.ico_next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    svg {
        display: block;
        width: 11px;
        height: 16px;
        fill: #A4A4A4;
        transition: 0.00s cubic-bezier(0.44, 0.18, 0.49, 0.99);
    }
    /*&:before {
        content: "";
        display: block;
        width: 11px;
        height: 16px;
        background-image: url(../img/common/ico_next.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }*/
}

.ico_prev_s {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 20px;
    svg {
        display: block;
        width: 11px;
        height: 16px;
        fill: #A4A4A4;
    }
    /*&:before {
        content: "";
        display: block;
        width: 8px;
        height: 7px;
        background-image: url(../img/common/ico_prev.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }*/
}

.ico_next_s {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 20px;
    svg {
        width: 8px;
        height: 7px;
        path {
            fill: $primary;
        }
    }
    /*&:before {
        content: "";
        display: block;
        width: 8px;
        height: 7px;
        background-image: url(../img/common/ico_next.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }*/
}

.ico_next_s2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 20px;
    &:before {
        content: "";
        display: block;
        width: 8px;
        height: 7px;
        background-image: url(../img/common/ico_next.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_star {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;
    &:before {
        content: "";
        display: block;
        width: 17px;
        height: 16px;
        background-image: url(../img/common/ico_star.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_star_w {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;
    &:before {
        content: "";
        display: block;
        width: 17px;
        height: 16px;
        background-image: url(../img/common/ico_star_w.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_star_s {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 11px;
    height: 11px;
    &:before {
        content: "";
        display: block;
        width: 11px;
        height: 11px;
        background-image: url(../img/common/ico_star.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_user {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
    &:before {
        content: "";
        display: block;
        width: 58px;
        height: 58px;
        background-image: url(../img/common/ico_user.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_tokyo_u {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
    &:before {
        content: "";
        display: block;
        width: 58px;
        height: 58px;
        background-image: url(../img/common/ico_tokyo_u.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_blank {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    @include mq(sp) {
        width: 13px;
        height: 13px;
        svg {
            width: 13px;
            height: 13px;
        }
    }
    /*&:before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        background-image: url(../img/common/ico_blank.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include mq(sp) {
            width: 13px;
            height: 13px;
        }
    }*/
}

.ico_copy {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21px;
    height: 21px;
    &:before {
        content: "";
        display: block;
        width: 21px;
        height: 21px;
        background-image: url(../img/common/ico_copy.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_line_w {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 23px;
    &:before {
        content: "";
        display: block;
        width: 25px;
        height: 23px;
        background-image: url(../img/common/ico_line_w.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_x_w {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19px;
    height: 20px;
    &:before {
        content: "";
        display: block;
        width: 19px;
        height: 20px;
        background-image: url(../img/common/ico_x_w.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_fb_w {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 13px;
    height: 23px;
    &:before {
        content: "";
        display: block;
        width: 13px;
        height: 23px;
        background-image: url(../img/common/ico_fb_w.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_detail {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10px;
    height: 10px;
    &:before {
        content: "";
        display: block;
        width: 8px;
        height: 7px;
        background-image: url(../img/common/ico_detail.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_more_arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background-color: $blue;
    border-radius: 100px;
    transition: 0.22s cubic-bezier(0.44, 0.18, 0.49, 0.99);
    @include mq(sp) {
        width: 26px;
        height: 26px;
    }
    svg {
        width: 10px;
        height: 16px;
        margin-right: -2px;
        transition: 0.22s cubic-bezier(0.44, 0.18, 0.49, 0.99);
        @include mq(sp) {
            width: 7px;
            height: 13px;
        }
        path {
            fill: $primary;
        }
    }
}

.ico-category {
    display: inline-block;
    padding: 6px 35px 3px;
    height: 30px;
    background-color: $red;
    border-radius: 100px;
    font-size: $font-size-16;
    line-height: 1.1em;
    color: $primary;
    font-style: normal;
    font-weight: $font-bold;
    box-sizing: border-box;
    @include mq(sp) {
        padding: 6px 25px 3px;
        height: 25px;
        font-size: $font-size-12;
    }
    &.c-red {
        background-color: $red;
    }
    &.c-yellow {
        color: $main;
        background-color: $yellow;
    }
    &.c-blue {
        background-color: $blue;
    }
    &.c-green {
        background-color: $green;
    }
}

.ico_snd_on {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    &:before {
        content: "";
        display: block;
        width: 35px;
        height: 35px;
        background-image: url(../img/common/ico_volume_on.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_snd_off {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    &:before {
        content: "";
        display: block;
        width: 35px;
        height: 35px;
        background-image: url(../img/common/ico_volume_off.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.ico_new {
    display: block;
    width: 66px;
    height: 68px;
    @include mq(sp) {
        width: 46px;
        height: 48px;
    }
    &:before {
        content: "";
        display: block;
        width: 66px;
        height: 68px;
        background-image: url(../img/news/ico_new.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include mq(sp) {
            width: 46px;
            height: 48px;
        }
    }
}