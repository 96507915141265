@charset "UTF-8";
@import "color";
@import "font";
body {
    //font-family: "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック";
    //font-family: "hiragino-kaku-gothic-pron", sans-serif;
    //font-family: "游ゴシック体", "YuGothic", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
    //font-family: HelveticaNowDisplay-Bd
    font-family: "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "Hiragino Sans", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
    font-weight: 400;
    position: relative;
    margin: 0;
    padding: 0;
    text-align: left;
    background-color: #fff;
    color: $main;
    letter-spacing: 0.09em;
    font-size: 1em;
    line-height: 1.8em;
    position: relative;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: visible;
    position: relative;
    font-feature-settings: "palt" 1;
    &.en {
        letter-spacing: .02em;
    }
    >img {
        display: none;
    }
    @media print {
        //transform: scale(0.8);
        //padding: 30px 0px;
        //font-size: 14px;
        min-width: 980px;
    }
}

@media screen and (max-width: 834px) {
    body {
        line-height: 1.8em;
    }
}

.cl:after {
    content: "";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
    overflow: hidden;
}

::selection,
 ::-moz-selection {
    background-color: #222;
    color: #fff;
}

#navskip {
    display: none;
}

abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: top;
    background: 0 0;
}

a {
    color: #000;
    text-decoration: none;
    transition: 0.15s cubic-bezier(0.44, 0.18, 0.49, 0.99);
    &:hover {
        color: #666;
        opacity: 0.7;
    }
}

svg.defs {
    height: 0;
    overflow: hidden;
    width: 0;
    overflow: hidden;
    position: absolute;
    left: -50%;
    display: none;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    padding: 0;
    line-height: 1.6em;
}

@media screen and (max-width: 834px) {
    h1,
    h2,
    h3,
    h4,
    h5 {
        line-height: 1.3em;
    }
}

figure {
    margin: 0;
    padding: 0;
}

img {
    vertical-align: top;
    max-width: 100%;
}