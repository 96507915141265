@charset "UTF-8";
@import "color";
@import "font";

/*
- Laptop : XL L
- Tablet : M
- Mobile : S XS
*/

.cl:after {
    content: "";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
    overflow: hidden;
}

figure {
    position: relative;
    img {
        position: relative;
        z-index: 1;
        pointer-events: none;
    }
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: 3;
        //pointer-events: au;
        display: block;
        //background-color: #222;
    }
}

::-moz-selection,
::selection {
    background-color: #222;
    color: #fff;
}

#navskip {
    display: none;
    color: $main;
    background-color: #fff;
}

a {
    color: $main;
    text-decoration: underline;
    transition: 0.22s cubic-bezier(0.44, 0.18, 0.49, 0.99);
    //font-weight: $font-normal;
    &:hover {
        opacity: 0.7;
        //color: $main;
        text-decoration: none;
    }
}

svg.defs {
    height: 0;
    overflow: hidden;
    width: 0;
    overflow: hidden;
    position: absolute;
    left: -50%;
    display: none;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    padding: 0;
    line-height: 1.6em;
    font-weight: 400;
    // font-family: 'Lato', 'Noto Sans JP', "Noto Sans JP rev=1", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
}

p {
    margin: 0;
    padding: 0;
    //font-family: 'Lato', 'Noto Sans JP', "Noto Sans JP rev=1", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
}

figure {
    margin: 0;
    padding: 0;
}

img {
    vertical-align: top;
    max-width: 100%;
}

.font-uppercase {
    text-transform: uppercase;
}

.font-style-italic {
    font-style: italic;
}

.font-style-normal {
    font-style: normal;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.line-height-13 {
    line-height: 1.3em;
}

.line-height-14 {
    line-height: 1.4em;
}

.line-height-15 {
    line-height: 1.5em;
}

.line-height-16 {
    line-height: 1.6em;
}

.pt-0 {
    padding-top: 0px;
}

.pt-5 {
    padding-top: 5px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-25 {
    padding-top: 25px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.pt-35 {
    padding-top: 35px !important;
}

.pt-40 {
    padding-top: 40px !important;
}

.pt-45 {
    padding-top: 45px !important;
}

.pt-50 {
    padding-top: 50px !important;
}

.pt-55 {
    padding-top: 55px !important;
}

.pt-60 {
    padding-top: 60px !important;
}

.pt-65 {
    padding-top: 65px !important;
}

.pt-70 {
    padding-top: 70px !important;
}

.pt-75 {
    padding-top: 75px !important;
}

.pt-80 {
    padding-top: 80px !important;
}

.pt-85 {
    padding-top: 85px !important;
}

.pt-90 {
    padding-top: 90px !important;
}

.pt-95 {
    padding-top: 95px !important;
}

.pt-100 {
    padding-top: 100px !important;
}

.pt-110 {
    padding-top: 110px !important;
}

.pt-115 {
    padding-top: 115px !important;
}

.pt-120 {
    padding-top: 120px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.pl-20 {
    padding-left: 20px !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.pl-30 {
    padding-left: 30px !important;
}

.pl-35 {
    padding-left: 35px !important;
}

.pl-40 {
    padding-left: 40px !important;
}

.pl-45 {
    padding-left: 45px !important;
}

.pl-50 {
    padding-left: 50px !important;
}

.pl-55 {
    padding-left: 55px !important;
}

.pl-60 {
    padding-left: 60px !important;
}

.pl-65 {
    padding-left: 65px !important;
}

.pl-70 {
    padding-left: 70px !important;
}

.pl-75 {
    padding-left: 75px !important;
}

.pl-80 {
    padding-left: 80px !important;
}

.pl-85 {
    padding-left: 85px !important;
}

.pl-90 {
    padding-left: 90px !important;
}

.pl-95 {
    padding-left: 95px !important;
}

.pl-100 {
    padding-left: 100px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.pr-20 {
    padding-right: 20px !important;
}

.pr-25 {
    padding-right: 25px !important;
}

.pr-30 {
    padding-right: 30px !important;
}

.pr-35 {
    padding-right: 35px !important;
}

.pr-40 {
    padding-right: 40px !important;
}

.pr-45 {
    padding-right: 45px !important;
}

.pr-50 {
    padding-right: 50px !important;
}

.pr-55 {
    padding-right: 55px !important;
}

.pr-60 {
    padding-right: 60px !important;
}

.pr-65 {
    padding-right: 65px !important;
}

.pr-70 {
    padding-right: 70px !important;
}

.pr-75 {
    padding-right: 75px !important;
}

.pr-80 {
    padding-right: 80px !important;
}

.pr-85 {
    padding-right: 85px !important;
}

.pr-90 {
    padding-right: 90px !important;
}

.pr-95 {
    padding-right: 95px !important;
}

.pr-100 {
    padding-right: 100px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pb-5 {
    padding-bottom: 5px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-25 {
    padding-bottom: 25px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pb-35 {
    padding-bottom: 35px !important;
}

.pb-40 {
    padding-bottom: 40px !important;
}

.pb-45 {
    padding-bottom: 45px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.pb-55 {
    padding-bottom: 55px !important;
}

.pb-60 {
    padding-bottom: 60px !important;
}

.pb-65 {
    padding-bottom: 65px !important;
}

.pb-70 {
    padding-bottom: 70px !important;
}

.pb-75 {
    padding-bottom: 75px !important;
}

.pb-80 {
    padding-bottom: 80px !important;
}

.pb-85 {
    padding-bottom: 85px !important;
}

.pb-90 {
    padding-bottom: 90px !important;
}

.pb-95 {
    padding-bottom: 95px !important;
}

.pb-100 {
    padding-bottom: 100px !important;
}

.pb-150 {
    padding-bottom: 150px !important;
}

.mr-auto {
    margin-right: auto !important;
}

.mr-0 {
    margin-right: 0px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mr-25 {
    margin-right: 25px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.mr-35 {
    margin-right: 35px !important;
}

.mr-40 {
    margin-right: 40px !important;
}

.mr-45 {
    margin-right: 45px !important;
}

.mr-50 {
    margin-right: 50px !important;
}

.mr-55 {
    margin-right: 55px !important;
}

.mr-60 {
    margin-right: 60px !important;
}

.mr-65 {
    margin-right: 65px !important;
}

.mr-70 {
    margin-right: 70px !important;
}

.mr-75 {
    margin-right: 75px !important;
}

.mr-80 {
    margin-right: 80px !important;
}

.mr-85 {
    margin-right: 85px !important;
}

.mr-90 {
    margin-right: 90px !important;
}

.mr-95 {
    margin-right: 95px !important;
}

.mr-100 {
    margin-right: 100px !important;
}

.ml-auto {
    margin-left: auto !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.ml-25 {
    margin-left: 25px !important;
}

.ml-30 {
    margin-left: 30px !important;
}

.ml-35 {
    margin-left: 35px !important;
}

.ml-40 {
    margin-left: 40px !important;
}

.ml-45 {
    margin-left: 45px !important;
}

.ml-50 {
    margin-left: 50px !important;
}

.ml-55 {
    margin-left: 55px !important;
}

.ml-60 {
    margin-left: 60px !important;
}

.ml-65 {
    margin-left: 65px !important;
}

.ml-70 {
    margin-left: 70px !important;
}

.ml-75 {
    margin-left: 75px !important;
}

.ml-80 {
    margin-left: 80px !important;
}

.ml-85 {
    margin-left: 85px !important;
}

.ml-90 {
    margin-left: 90px !important;
}

.ml-95 {
    margin-left: 95px !important;
}

.ml-100 {
    margin-left: 100px !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-35 {
    margin-bottom: 35px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-45 {
    margin-bottom: 45px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-55 {
    margin-bottom: 55px !important;
}

.mb-60 {
    margin-bottom: 60px !important;
}

.mb-65 {
    margin-bottom: 65px !important;
}

.mb-70 {
    margin-bottom: 70px !important;
}

.mb-75 {
    margin-bottom: 75px !important;
}

.mb-80 {
    margin-bottom: 80px !important;
}

.mb-85 {
    margin-bottom: 85px !important;
}

.mb-90 {
    margin-bottom: 90px !important;
}

.mb-95 {
    margin-bottom: 95px !important;
}

.mb-100 {
    margin-bottom: 100px !important;
}

.mb-110 {
    margin-bottom: 110px !important;
}

.mb-140 {
    margin-bottom: 140px !important;
}

.mb-145 {
    margin-bottom: 145px !important;
}

.mb-195 {
    margin-bottom: 195px !important;
}

.mt-auto {
    margin-top: auto;
}

.mt-0 {
    margin-top: 0;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-35 {
    margin-top: 35px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mt-45 {
    margin-top: 45px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-55 {
    margin-top: 55px !important;
}

.mt-60 {
    margin-top: 60px !important;
}

.mt-65 {
    margin-top: 65px !important;
}

.mt-70 {
    margin-top: 70px !important;
}

.mt-75 {
    margin-top: 75px !important;
}

.mt-80 {
    margin-top: 80px !important;
}

.mt-85 {
    margin-top: 85px !important;
}

.mt-90 {
    margin-top: 90px !important;
}

.mt-95 {
    margin-top: 95px !important;
}

.mt-100 {
    margin-top: 100px !important;
}

.mt-110 {
    margin-top: 110px !important;
}

.mt-120 {
    margin-top: 120px !important;
}

.mt-130 {
    margin-top: 130px !important;
}

.mt-170 {
    margin-top: 170px !important;
}

.sp {
    display: none;
}

.pc {
    display: block;
}

.f-bold {
    font-weight: $font-bold;
}

.f-normal {
    font-weight: $font-normal;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.align-left {
    text-align: left;
}

@include mq(sp) {
    .align-right-sp {
        text-align: right;
    }
    .align-center-sp {
        text-align: center;
    }
    .align-left-sp {
        text-align: left;
    }
}

.f-24 {
    font-size: $font-size-24 !important;
}

.f-20 {
    font-size: $font-size-20 !important;
}

.f-18 {
    font-size: $font-size-18 !important;
}

@include mq(sp) {
    .pc {
        display: none;
    }
    .sp {
        display: block;
    }
    .s_pt-0 {
        padding-top: 0px !important;
    }
    .s_pt-5 {
        padding-top: 5px !important;
    }
    .s_pt-10 {
        padding-top: 10px !important;
    }
    .s_pt-15 {
        padding-top: 15px !important;
    }
    .s_pt-20 {
        padding-top: 20px !important;
    }
    .s_pt-25 {
        padding-top: 25px !important;
    }
    .s_pt-30 {
        padding-top: 30px !important;
    }
    .s_pt-35 {
        padding-top: 35px !important;
    }
    .s_pt-40 {
        padding-top: 40px !important;
    }
    .s_pt-45 {
        padding-top: 45px !important;
    }
    .s_pt-50 {
        padding-top: 50px !important;
    }
    .s_pt-55 {
        padding-top: 55px !important;
    }
    .s_pt-60 {
        padding-top: 60px !important;
    }
    .s_pt-65 {
        padding-top: 65px !important;
    }
    .s_pt-70 {
        padding-top: 70px !important;
    }
    .s_pt-75 {
        padding-top: 75px !important;
    }
    .s_pt-80 {
        padding-top: 80px !important;
    }
    .s_pt-85 {
        padding-top: 85px !important;
    }
    .s_pt-90 {
        padding-top: 90px !important;
    }
    .s_pt-95 {
        padding-top: 95px !important;
    }
    .s_pt-100 {
        padding-top: 100px !important;
    }
    .s_pl-0 {
        padding-left: 0px !important;
    }
    .s_pl-5 {
        padding-left: 5px !important;
    }
    .s_pl-10 {
        padding-left: 10px !important;
    }
    .s_pl-15 {
        padding-left: 15px !important;
    }
    .s_pl-20 {
        padding-left: 20px !important;
    }
    .s_pl-25 {
        padding-left: 25px !important;
    }
    .s_pl-30 {
        padding-left: 30px !important;
    }
    .s_pl-35 {
        padding-left: 35px !important;
    }
    .s_pl-40 {
        padding-left: 40px !important;
    }
    .s_pl-45 {
        padding-left: 45px !important;
    }
    .s_pl-50 {
        padding-left: 50px !important;
    }
    .s_pl-55 {
        padding-left: 55px !important;
    }
    .s_pl-60 {
        padding-left: 60px !important;
    }
    .s_pl-65 {
        padding-left: 65px !important;
    }
    .s_pl-70 {
        padding-left: 70px !important;
    }
    .s_pl-75 {
        padding-left: 75px !important;
    }
    .s_pl-80 {
        padding-left: 80px !important;
    }
    .s_pl-85 {
        padding-left: 85px !important;
    }
    .s_pl-90 {
        padding-left: 90px !important;
    }
    .s_pl-95 {
        padding-left: 95px !important;
    }
    .s_pl-100 {
        padding-left: 100px !important;
    }
    .s_pr-0 {
        padding-right: 0px !important;
    }
    .s_pr-5 {
        padding-right: 5px !important;
    }
    .s_pr-10 {
        padding-right: 10px !important;
    }
    .s_pr-15 {
        padding-right: 15px !important;
    }
    .s_pr-20 {
        padding-right: 20px !important;
    }
    .s_pr-25 {
        padding-right: 25px !important;
    }
    .s_pr-30 {
        padding-right: 30px !important;
    }
    .s_pr-35 {
        padding-right: 35px !important;
    }
    .s_pr-40 {
        padding-right: 40px !important;
    }
    .s_pr-45 {
        padding-right: 45px !important;
    }
    .s_pr-50 {
        padding-right: 50px !important;
    }
    .s_pr-55 {
        padding-right: 55px !important;
    }
    .s_pr-60 {
        padding-right: 60px !important;
    }
    .s_pr-65 {
        padding-right: 65px !important;
    }
    .s_pr-70 {
        padding-right: 70px !important;
    }
    .s_pr-75 {
        padding-right: 75px !important;
    }
    .s_pr-80 {
        padding-right: 80px !important;
    }
    .s_pr-85 {
        padding-right: 85px !important;
    }
    .s_pr-90 {
        padding-right: 90px !important;
    }
    .s_pr-95 {
        padding-right: 95px !important;
    }
    .s_pr-100 {
        padding-right: 100px !important;
    }
    .s_pb-0 {
        padding-bottom: 0px !important;
    }
    .s_pb-5 {
        padding-bottom: 5px !important;
    }
    .s_pb-10 {
        padding-bottom: 10px !important;
    }
    .s_pb-15 {
        padding-bottom: 15px !important;
    }
    .s_pb-20 {
        padding-bottom: 20px !important;
    }
    .s_pb-25 {
        padding-bottom: 25px !important;
    }
    .s_pb-30 {
        padding-bottom: 30px !important;
    }
    .s_pb-35 {
        padding-bottom: 35px !important;
    }
    .s_pb-40 {
        padding-bottom: 40px !important;
    }
    .s_pb-45 {
        padding-bottom: 45px !important;
    }
    .s_pb-50 {
        padding-bottom: 50px !important;
    }
    .s_pb-55 {
        padding-bottom: 55px !important;
    }
    .s_pb-60 {
        padding-bottom: 60px !important;
    }
    .s_pb-65 {
        padding-bottom: 65px !important;
    }
    .s_pb-70 {
        padding-bottom: 70px !important;
    }
    .s_pb-75 {
        padding-bottom: 75px !important;
    }
    .s_pb-80 {
        padding-bottom: 80px !important;
    }
    .s_pb-85 {
        padding-bottom: 85px !important;
    }
    .s_pb-90 {
        padding-bottom: 90px !important;
    }
    .s_pb-95 {
        padding-bottom: 95px !important;
    }
    .s_pb-100 {
        padding-bottom: 100px !important;
    }
    .s_pb-150 {
        padding-bottom: 150px !important;
    }
    .s_mr-0 {
        margin-right: 0px !important;
    }
    .s_mr-5 {
        margin-right: 5px !important;
    }
    .s_mr-10 {
        margin-right: 10px !important;
    }
    .s_mr-15 {
        margin-right: 15px !important;
    }
    .s_mr-20 {
        margin-right: 20px !important;
    }
    .s_mr-25 {
        margin-right: 25px !important;
    }
    .s_mr-30 {
        margin-right: 30px !important;
    }
    .s_mr-35 {
        margin-right: 35px !important;
    }
    .s_mr-40 {
        margin-right: 40px !important;
    }
    .s_mr-45 {
        margin-right: 45px !important;
    }
    .s_mr-50 {
        margin-right: 50px !important;
    }
    .s_mr-55 {
        margin-right: 55px !important;
    }
    .s_mr-60 {
        margin-right: 60px !important;
    }
    .s_mr-65 {
        margin-right: 65px !important;
    }
    .s_mr-70 {
        margin-right: 70px !important;
    }
    .s_mr-75 {
        margin-right: 75px !important;
    }
    .s_mr-80 {
        margin-right: 80px !important;
    }
    .s_mr-85 {
        margin-right: 85px !important;
    }
    .s_mr-90 {
        margin-right: 90px !important;
    }
    .s_mr-95 {
        margin-right: 95px !important;
    }
    .s_mr-100 {
        margin-right: 100px !important;
    }
    .s_ml-0 {
        margin-left: 0px !important;
    }
    .s_ml-5 {
        margin-left: 5px !important;
    }
    .s_ml-10 {
        margin-left: 10px !important;
    }
    .s_ml-15 {
        margin-left: 15px !important;
    }
    .s_ml-20 {
        margin-left: 20px !important;
    }
    .s_ml-25 {
        margin-left: 25px !important;
    }
    .s_ml-30 {
        margin-left: 30px !important;
    }
    .s_ml-35 {
        margin-left: 35px !important;
    }
    .s_ml-40 {
        margin-left: 40px !important;
    }
    .s_ml-45 {
        margin-left: 45px !important;
    }
    .s_ml-50 {
        margin-left: 50px !important;
    }
    .s_ml-55 {
        margin-left: 55px !important;
    }
    .s_ml-60 {
        margin-left: 60px !important;
    }
    .s_ml-65 {
        margin-left: 65px !important;
    }
    .s_ml-70 {
        margin-left: 70px !important;
    }
    .s_ml-75 {
        margin-left: 75px !important;
    }
    .s_ml-80 {
        margin-left: 80px !important;
    }
    .s_ml-85 {
        margin-left: 85px !important;
    }
    .s_ml-90 {
        margin-left: 90px !important;
    }
    .s_ml-95 {
        margin-left: 95px !important;
    }
    .s_ml-100 {
        margin-left: 100px !important;
    }
    .s_mb-0 {
        margin-bottom: 0px !important;
    }
    .s_mb-5 {
        margin-bottom: 5px !important;
    }
    .s_mb-10 {
        margin-bottom: 10px !important;
    }
    .s_mb-15 {
        margin-bottom: 15px !important;
    }
    .s_mb-20 {
        margin-bottom: 20px !important;
    }
    .s_mb-25 {
        margin-bottom: 25px !important;
    }
    .s_mb-30 {
        margin-bottom: 30px !important;
    }
    .s_mb-35 {
        margin-bottom: 35px !important;
    }
    .s_mb-40 {
        margin-bottom: 40px !important;
    }
    .s_mb-45 {
        margin-bottom: 45px !important;
    }
    .s_mb-50 {
        margin-bottom: 50px !important;
    }
    .s_mb-55 {
        margin-bottom: 55px !important;
    }
    .s_mb-60 {
        margin-bottom: 60px !important;
    }
    .s_mb-65 {
        margin-bottom: 65px !important;
    }
    .s_mb-70 {
        margin-bottom: 70px !important;
    }
    .s_mb-75 {
        margin-bottom: 75px !important;
    }
    .s_mb-80 {
        margin-bottom: 80px !important;
    }
    .s_mb-85 {
        margin-bottom: 85px !important;
    }
    .s_mb-90 {
        margin-bottom: 90px !important;
    }
    .s_mb-95 {
        margin-bottom: 95px !important;
    }
    .s_mb-100 {
        margin-bottom: 100px !important;
    }
    .s_mt-0 {
        margin-top: 0px !important;
    }
    .s_mt-5 {
        margin-top: 5px !important;
    }
    .s_mt-10 {
        margin-top: 10px !important;
    }
    .s_mt-15 {
        margin-top: 15px !important;
    }
    .s_mt-20 {
        margin-top: 20px !important;
    }
    .s_mt-25 {
        margin-top: 25px !important;
    }
    .s_mt-30 {
        margin-top: 30px !important;
    }
    .s_mt-35 {
        margin-top: 35px !important;
    }
    .s_mt-40 {
        margin-top: 40px !important;
    }
    .s_mt-45 {
        margin-top: 45px !important;
    }
    .s_mt-50 {
        margin-top: 50px !important;
    }
    .s_mt-55 {
        margin-top: 55px !important;
    }
    .s_mt-60 {
        margin-top: 60px !important;
    }
    .s_mt-65 {
        margin-top: 65px !important;
    }
    .s_mt-70 {
        margin-top: 70px !important;
    }
    .s_mt-75 {
        margin-top: 75px !important;
    }
    .s_mt-80 {
        margin-top: 80px !important;
    }
    .s_mt-85 {
        margin-top: 85px !important;
    }
    .s_mt-90 {
        margin-top: 90px !important;
    }
    .s_mt-95 {
        margin-top: 95px !important;
    }
    .s_mt-100 {
        margin-top: 100px !important;
    }
    .s_mt-110 {
        margin-top: 110px !important;
    }
    .s_mt-120 {
        margin-top: 120px !important;
    }
    .s_mt-130 {
        margin-top: 130px !important;
    }
    .s_mt-140 {
        margin-top: 140px !important;
    }
    .s_mt-170 {
        margin-top: 170px !important;
    }
    .s_mt-180 {
        margin-top: 180px !important;
    }
}

@media screen and (max-width: 320px) {
    .pc {
        display: none;
    }
    .sp {
        display: block;
    }
}

.l-container {
    width: 100%;
    .l-row {
        display: flex;
        flex-wrap: wrap;
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
        .l-col {
            margin-left: 20px;
            margin-right: 20px;
            width: calc((100% - 40px * 1)/ 1);
            margin-bottom: 40px;
            box-sizing: border-box;
        }
        .l-col-2 {
            margin-left: 20px;
            margin-right: 20px;
            width: calc((100% - 40px * 2)/ 2);
            margin-bottom: 60px;
            box-sizing: border-box;
            @media screen and (max-width: 768px) {
                width: calc((100% - 30px * 1)/ 1);
                margin-bottom: 15px;
                /*&:last-child {
                    margin-bottom: 0;
                }*/
            }
        }
        .l-col-3 {
            margin-left: 20px;
            margin-right: 20px;
            width: calc((100% - 40px * 3)/ 3);
            margin-bottom: 60px;
            box-sizing: border-box;
            @media screen and (max-width: 768px) {
                width: calc((100% - 30px * 1)/ 1);
                margin-bottom: 15px;
                &.l-col-2-sp {
                    width: calc((100% - 30px * 2)/ 2);
                    margin-bottom: 15px;
                }
            }
        }
        .l-col-3-1 {
            margin-left: 20px;
            margin-right: 20px;
            width: calc((100% - 40px * 2)/ 3);
            margin-bottom: 60px;
            box-sizing: border-box;
            @media screen and (max-width: 768px) {
                width: calc((100% - 30px * 1)/ 1);
                margin-bottom: 15px;
            }
        }
        .l-col-3-2 {
            margin-left: 20px;
            margin-right: 20px;
            width: calc(((100% - 40px * 2)/ 3)*2);
            margin-bottom: 30px;
            box-sizing: border-box;
            @media screen and (max-width: 768px) {
                width: calc((100% - 30px * 1)/ 1);
                margin-bottom: 15px;
            }
        }
        .l-col-4 {
            margin-left: 20px;
            margin-right: 20px;
            width: calc((100% - 40px * 4)/ 4);
            margin-bottom: 60px;
            box-sizing: border-box;
            @media screen and (max-width: 768px) {
                width: calc((100% - 30px * 1)/ 1);
                margin-bottom: 15px;
            }
        }
        .l-col-4-1 {
            margin-left: 15px;
            margin-right: 15px;
            width: calc((100% - 30px * 2)/ 4);
            margin-bottom: 30px;
            box-sizing: border-box;
            @media screen and (max-width: 768px) {
                width: calc((100% - 30px * 1)/ 1);
                margin-bottom: 15px;
            }
        }
        .l-col-4-3 {
            margin-left: 15px;
            margin-right: 15px;
            width: calc(((100% - 30px * 2)/ 4)*3);
            margin-bottom: 30px;
            box-sizing: border-box;
            @media screen and (max-width: 768px) {
                width: calc((100% - 30px * 1)/ 1);
                margin-bottom: 15px;
            }
        }
    }
    .l-row-column {
        flex-direction: column;
        .l-col,
        .l-col-2,
        .l-col-3,
        .l-col-4 {
            margin-left: 15px;
            margin-right: 15px;
            width: calc((100% - 30px)/ 1);
            margin-bottom: 30px;
        }
    }
    .l-row-reverse {
        flex-direction: row-reverse;
    }
}

.l-btns {
    display: flex;
    flex-wrap: wrap;
    .l-btn {
        border: none;
        border-radius: 9px;
        width: 257px;
        height: 61px;
        background-color: $primary;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 15px 10px 15px;
        box-sizing: border-box;
        position: relative;
        transition: 0.15s cubic-bezier(0.44, 0.18, 0.49, 0.99);
        font-weight: 600;
        font-size: $font-size-13;
        //color: #fff;
        color: $primary;
        cursor: pointer;
        text-decoration: none;
        margin-left: auto;
        margin-right: auto;
        //font-family: "游ゴシック体", "YuGothic", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
        @include mq(sp) {
            max-width: 230px;
            height: 52px;
        }
        &.l-back-btn-min {
            /* Group 818 */
            position: absolute;
            max-width: 240px;
            height: 42px;
            //left: calc(50% - 383px/2 + 0.5px);
            border-radius: 100px;
            background-color: $blue;
            font-size: $font-size-16;
            color: $primary;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            @include mq(sp) {
                font-size: $font-size-14;
                height: 36px;
                max-width: 180px;
            }
        }
        &.l-back-btn {
            /* Group 818 */
            position: absolute;
            max-width: 383px;
            height: 69px;
            //left: calc(50% - 383px/2 + 0.5px);
            border-radius: 100px;
            background-color: $blue;
            font-size: $font-size-24;
            color: $primary;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            @include mq(sp) {
                font-size: $font-size-18;
                height: 46px;
                max-width: 280px;
            }
        }
        &.l-mordal-btn {
            margin-top: 1em;
            border-radius: 100px;
            margin-left: 0px;
            height: 38px;
            max-width: 80px;
            font-size: $font-size-13;
            padding-bottom: 8px;
            margin-left: 15px;
            color: $main;
            @include mq(sp) {
                font-size: $font-size-11;
                max-width: 55px;
                padding: 10px 10px 10px 10px;
                padding-bottom: 8px;
                height: 32px;
                margin-left: 8px;
            }
        }
        &.l-googlemap-btn {
            margin-top: 1em;
            border-radius: 100px;
            margin-left: 0px;
            height: 38px;
            max-width: 180px;
            font-size: $font-size-13;
            padding-bottom: 8px;
            margin-right: 0px;
            color: $main;
            @include mq(sp) {
                font-size: $font-size-11;
                max-width: 115px;
                padding: 10px 10px 10px 10px;
                padding-bottom: 8px;
                height: 32px;
            }
        }
        &.load-data {
            .ico_plus {
                &:before {
                    width: 15px;
                    height: 15px;
                    //border-radius: 100px;
                    border: solid 1px #fff;
                    left: 0px;
                    top: 0px;
                    transform: translateX(0) translateY(0);
                    background-color: transparent;
                    box-sizing: border-box;
                    display: none;
                    border-radius: 50%;
                    box-sizing: border-box;
                    animation: rotation 1s linear infinite;
                }
                &:after {
                    width: 15px;
                    height: 15px;
                    //border-radius: 100px;
                    border: solid 1px #fff;
                    left: 0px;
                    top: 0px;
                    transform: translateX(0) translateY(0);
                    background-color: transparent;
                    box-sizing: border-box;
                    border-bottom-color: $primary;
                    border-radius: 50%;
                    box-sizing: border-box;
                    animation: rotation 1s linear infinite;
                }
                @keyframes rotation {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
        }
        &.l-btn-more {
            background-color: #F7F0FF;
            border-radius: 6px;
            font-size: $font-size-14;
            min-width: 125px;
            width: 100%;
            color: $primary;
            height: 32px;
            padding: 0px;
            padding-right: 10px;
            padding-left: 10px;
            letter-spacing: 0.02em;
            white-space: nowrap;
            .ico_next_s {
                margin-left: 3px;
                width: 20px;
                path {
                    transition: 0.15s cubic-bezier(0.44, 0.18, 0.49, 0.99);
                }
            }
            //justify-content: flex-end;
            &:hover {
                background-color: $primary;
                color: #fff;
                opacity: 1;
                .ico_next_s {
                    path {
                        fill: #fff;
                    }
                    fill: #fff;
                }
            }
            @include mq(sp) {
                height: 32px;
                //padding: 0px;
            }
        }
        &.l-btn-all {
            border: solid 2px $primary;
            background-color: #fff;
            border-radius: 100px;
            font-size: $font-size-16;
            max-width: 492px;
            width: 100%;
            color: $primary;
            height: 76px;
            //justify-content: flex-end;
            &:hover {
                background-color: $primary;
                color: #fff;
                opacity: 1;
                .ico_next {
                    &:before {
                        background-image: url(../img/common/ico_next_w.svg);
                    }
                }
            }
            @include mq(sp) {
                height: 64px;
                padding: 0px;
            }
        }
        /*&.l-btn-labo {
            border: solid 2px $primary;
            background-color: #fff;
            border-radius: 100px;
            font-size: $font-size-18;
            max-width: 718px;
            width: 100%;
            color: $primary;
            height: 76px;
            @include mq(sp) {
                max-width: 290px;
                height: 64px;
            }
            .ico_blank,
            .ico_blank path {
                transition: 0.15s cubic-bezier(0.44, 0.18, 0.49, 0.99);
            }
            &:hover {
                background-color: $primary;
                color: #fff;
                opacity: 1;
                .ico_blank {
                    path {
                        fill: #fff;
                    }
                    fill: #fff;
                }
            }
        }
        &.l-btn-comment-more {
            background-color: #fff;
            border-radius: 6px;
            font-size: $font-size-14;
            max-width: 210px;
            width: 100%;
            color: $primary;
            border: solid 1px $primary;
            height: 36px;
            //justify-content: flex-end;
            &:hover {
                background-color: $primary;
                color: #fff;
                opacity: 1;
            }
        }*/
        &.l-btn-detail {
            background-color: transparent;
            border-radius: 0px;
            width: 155px;
            height: auto;
            padding: 0px;
            margin-right: 0px;
            padding-bottom: 5px;
            padding-right: 5px;
            justify-content: flex-end;
            //border-bottom: solid 1px #fff;
            font-weight: $font-bold;
            font-size: $font-size-14;
            color: #fff;
            position: relative;
            padding-bottom: 1px;
            transition: 0.22s cubic-bezier(0.44, 0.18, 0.49, 0.99);
            transform-origin: top bottom;
            span {
                transition: 0.22s cubic-bezier(0.44, 0.18, 0.49, 0.99);
                position: relative;
            }
            overflow: hidden;
            &::before {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background-color: #fff;
                position: absolute;
                left: 0px;
                bottom: 0px;
                transition: 0.22s cubic-bezier(0.44, 0.18, 0.49, 0.99) 0.2s;
                transform: translateX(-100%);
            }
            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background-color: #fff;
                position: absolute;
                left: 0px;
                bottom: 0px;
                transition: 0.15s cubic-bezier(0.44, 0.18, 0.49, 0.99);
                transform: translateX(0);
            }
            &:hover {
                opacity: 1;
                /*transform: scale(1.02);
                &::after {
                    height: 2px;
                }*/
            }
        }
        &:hover {
            opacity: 0.7;
        }
        .ico_plus {
            position: absolute;
            right: 17px;
            top: calc(50% - 7px);
        }
        .ico_more {
            //transform: rotate(180deg);
            position: absolute;
            right: 17px;
        }
        @include mq(sp) {
            width: 100%;
            font-size: $font-size-15;
        }
    }
}

.l-btns-left {
    justify-content: flex-start;
}

.l-btns-right {
    justify-content: flex-end;
}

.l-btns-center {
    justify-content: center;
}

.l-btns-center-s {
    @media screen and (max-width: 560px) {
        justify-content: center;
        .l-btn {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.l-btns-right {
    justify-content: flex-end;
}

.l-btns-middle {
    align-items: center;
}