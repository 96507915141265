@import './reset.scss';

/*
 - default style
*/

@import './mixin.scss';
@import './color.scss';
@import './icons.scss';
@import './base.scss';
@import './header.scss';
@import './footer.scss';
//@import './side.scss';
@import './layout.scss';
@import './module.scss';

/*
 - page style
*/

@import './_pages/index.scss';
@import './_pages/low.scss';