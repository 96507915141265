@charset "UTF-8";
.fade {
    opacity: 0;
    transform: translateY(15px);
}

.l-main {
    &__outer {
        @include mq(sp) {}
    }
    &__inner {
        max-width: 100%;
        padding: 0px;
        margin-left: auto;
        margin-right: auto;
        @include mq(sp) {
            padding: 0px 0px;
        }
    }
}

.l-mainvidual {
    &__outer {
        max-width: 100%;
        margin-bottom: 0px;
        @include mq(sp) {
            margin-left: 0px;
            margin-right: 0px;
            //margin-bottom: 20px;
        }
    }
    &__inner {
        max-width: 100%;
        position: relative;
        text-align: left;
        padding-top: 0px;
        padding-bottom: 0px;
        @include mq(sp) {
            padding-top: 30px;
            padding-bottom: 5px;
        }
    }
    &-title {
        font-size: $font-size-32;
        font-weight: 600;
        color: $primary;
        text-align: left;
        line-height: 1.4em;
        font-family: HelveticaNowDisplay-Bd, 'HelveticaNowDisplay-Bd', sans-serif;
        @include mq(sp) {
            font-size: $font-size-21;
        }
        &__outer {
            display: block;
            @include mq(sp) {
                margin-bottom: 10px;
            }
        }
        &__inner {
            display: inline-block;
            //padding-left: 25px;
            //padding-right: 25px;
            padding: 0;
            padding-bottom: 0px;
            position: relative;
            @include mq(sp) {
                margin-bottom: 10px;
            }
        }
        &-en {
            font-size: $font-size-64;
            font-weight: 600;
            line-height: 1.6em;
            color: $primary;
            //font-family: "Roboto", Helvetica Now Display, Helvetica, sans-serif;
            font-family: HelveticaNowDisplay-Bd, 'HelveticaNowDisplay-Bd', sans-serif;
        }
        &-read {
            font-size: $font-size-20;
            font-weight: 600;
            margin-bottom: 0px;
            margin-top: 10px;
            @include mq(sp) {
                font-size: $font-size-16;
            }
        }
    }
    &-read {
        font-size: $font-size-20;
        font-weight: bold;
        color: $main;
        text-align: left;
        line-height: 1.5em;
        @include mq(sp) {
            font-size: $font-size-16;
        }
        &__outer {
            display: block;
            padding-left: 0px;
            padding-bottom: 28px;
            @include mq(sp) {
                padding-left: 0;
                padding-bottom: 0px;
            }
        }
    }
    &-title-read {
        &__outer {
            position: relative;
            margin-bottom: 0px;
            width: 100%;
            @include mq(sp) {
                display: block;
                margin-bottom: 0px;
            }
        }
    }
}

.l-breadcrumb {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    &__outer {
        padding-top: 30px;
        padding-bottom: 35px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        background-color: #f5f5f7;
        @include mq(sp) {
            margin-left: 0px;
            margin-right: 0px;
            margin-bottom: 0px;
            padding: 10px 25px;
        }
    }
    ul {
        list-style: none;
        display: inline;
        li {
            display: inline;
            font-size: $font-size-14;
            @include mq(sp) {
                font-size: $font-size-12;
            }
        }
        li+li {
            &:before {
                content: ">";
                display: inline;
                color: #888888;
                margin-left: 3px;
                margin-right: 4px;
            }
        }
    }
}

.l-section {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
    @include mq(sp) {
        margin-left: 0px;
        margin-right: 0px;
        //margin-bottom: 15px;
    }
    &__outer {}
    &__inner {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 80px;
        @include mq(sp) {
            padding-bottom: 20px;
        }
        &:last-child {
            padding-bottom: 0px;
            @include mq(sp) {
                padding-bottom: 0px;
            }
        }
        p {
            font-size: $font-size-16;
            //word-break: break-all;
            word-break: break-word;
            margin-bottom: 20px;
            @include mq(sp) {
                font-size: $font-size-14;
            }
            @include mq(sp) {
                &.l-sakagura-card-label {
                    font-size: $font-size-11;
                }
            }
            &.l-section-read {
                padding-top: 20px;
                font-size: $font-size-16;
                font-weight: 600;
                @include mq(sp) {
                    padding-top: 0px;
                    font-size: $font-size-15;
                }
            }
        }
        ul+.l-section-title__outer,
        ol+.l-section-title__outer,
        p+.l-section-title__outer {
            margin-top: 50px;
        }
        ol,
        ul {
            margin-left: 1.5em;
            li {
                margin-bottom: 1em;
            }
        }
    }
    &-title {
        font-size: $font-size-48;
        font-weight: 600;
        text-align: left;
        color: $main;
        //font-family: "Roboto", Helvetica Now Display, Helvetica, sans-serif;
        font-family: HelveticaNowDisplay-Bd, 'HelveticaNowDisplay-Bd', sans-serif;
        &.c-red {
            color: #FF0000;
        }
        &.c-yellow {
            color: $yellow;
        }
        &.c-blue {
            color: $blue;
        }
        &.c-green {
            color: #00AA2D;
        }
        @include mq(sp) {
            font-size: $font-size-18;
        }
        small {
            font-size: $font-size-19;
            @include mq(sp) {
                font-size: $font-size-16;
            }
        }
        &__outer {
            margin-bottom: 25px;
            @include mq(sp) {
                margin-bottom: 10px;
            }
        }
        &__inner {
            padding: 0px;
            border-radius: 8px;
            p {
                font-size: $font-size-24;
                font-weight: 600;
                text-align: left;
                color: $main;
                @include mq(sp) {
                    font-size: $font-size-16;
                }
            }
        }
    }
    &-read {
        @include mq(sp) {
            text-align: left;
        }
        &__outer {
            margin-bottom: 75px;
            @include mq(sp) {
                margin-bottom: 35px;
                font-size: $font-size-15;
                font-weight: bold;
            }
        }
        &.w470 {
            max-width: 470px;
            margin-left: auto;
            margin-right: auto;
            font-weight: 600;
            @include mq(sp) {
                font-size: $font-size-15;
            }
        }
    }
    &-title-read {
        &__outer {
            padding-bottom: 55px;
        }
        &__inner {
            display: flex;
            align-items: center;
            @include mq(sp) {
                display: block;
                flex-direction: column;
            }
            .l-section-title {
                font-size: $font-size-80;
                line-height: 1.4em;
                @include mq(sp) {
                    font-size: $font-size-40;
                }
                &__outer {
                    margin-bottom: 0;
                }
                &__inner {
                    //padding-bottom: 10px;
                }
            }
            .l-section-read {
                &__outer {
                    margin-left: auto;
                    margin-bottom: 0;
                    padding-top: 16px;
                }
                &__inner {
                    p {
                        text-align: right;
                        font-size: $font-size-16;
                        //font-weight: $font-bold;
                        @include mq(sp) {
                            text-align: left;
                            //margin-bottom: 0px;
                            br {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    &-sub-title {
        font-size: $font-size-20;
        font-weight: 600;
        text-align: left;
        &__outer {
            margin-top: 50px;
            margin-bottom: 15px;
            @include mq(sp) {
                margin-bottom: 10px;
            }
        }
    }
    &-sub-read {
        font-size: $font-size-14;
        font-weight: 600;
        color: $primary;
        text-align: center;
        &__outer {
            margin-bottom: 75px;
        }
    }
    &-header {
        display: flex;
        align-items: flex-end;
        @include mq(sp) {
            flex-direction: column;
            align-items: flex-start;
            /*.l-section-title__outer {
                margin-bottom: 18px;
            }*/
            .l-select__outer {
                margin-left: auto;
                margin-right: 0px;
            }
        }
        &__outer {
            margin-bottom: 40px;
            @include mq(sp) {
                margin-bottom: 20px;
            }
        }
        .l-section-title__outer {
            margin-bottom: 0px;
            @include mq(sp) {
                margin-bottom: 15px;
            }
        }
    }
    .l-entry-post {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 0px;
        line-break: strict;
        //word-break: break-all;
        word-break: break-word;
        @include mq(sp) {
            padding-bottom: 0px;
        }
        &>* {
            margin-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 40px;
            @include mq(sp) {
                margin-bottom: 30px;
            }
        }
        &-meta {
            padding-top: 2em;
            p {
                text-align: right;
                font-size: $font-size-14 !important;
                color: #666;
                //font-weight: 600;
                @include mq(sp) {
                    font-size: $font-size-13 !important;
                }
            }
        }
        &__outer {
            max-width: 1140px;
            margin-left: auto;
            margin-right: auto;
            background-color: #FFFFFF;
            border-radius: 30px;
            padding: 55px;
            box-sizing: border-box;
            @include mq(sp) {
                border-radius: 20px;
                padding: 15px 20px;
            }
        }
        .l-cat {
            margin: 0;
            padding: 0;
            padding-bottom: 10px;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            &__outer {
                margin-bottom: 0px;
            }
            li {
                margin-right: 14px;
                margin-bottom: 13px;
                @include mq(sp) {
                    margin-right: 9px;
                    margin-bottom: 9px;
                }
                a {
                    display: flex;
                    align-items: center;
                    padding: 4px 15px;
                    border-radius: 100px;
                    background-color: $other;
                    color: $main;
                    font-size: $font-size-12;
                    line-height: 1.1em;
                    font-weight: 600;
                    white-space: nowrap;
                    text-align: center;
                    text-decoration: none;
                    min-height: 28px;
                    box-sizing: border-box;
                    line-height: 1.1em;
                    @include mq(sp) {
                        padding: 2px 15px;
                        font-size: $font-size-10;
                        min-height: 21px;
                    }
                    &.c-yellow {
                        background-color: $yellow;
                        color: $main;
                    }
                    &.c-blue {
                        background-color: $blue;
                        color: $primary;
                    }
                    &.c-red {
                        background-color: $red;
                        color: $primary;
                    }
                    &.c-green {
                        background-color: $green;
                        color: $primary;
                    }
                }
            }
        }
        .l-date {
            &__outer {
                margin-bottom: 20px;
            }
            font-size: $font-size-16;
            margin-left: auto;
            text-align: right;
            display: block;
            color: #646464;
            font-weight: 500;
            @include mq(sp) {
                font-size: $font-size-13;
            }
        }
        .l-eyecatch {
            border-radius: 30px;
            img {
                height: auto;
                border-radius: 30px;
                @include mq(sp) {
                    border-radius: 20px;
                }
            }
            &__outer {
                padding-bottom: 0px;
            }
        }
        h1 {
            font-size: $font-size-32;
            line-height: 1.8em;
            font-weight: 600;
            color: $main;
            margin-bottom: 20px;
            text-align: left;
            @include mq(sp) {
                font-size: $font-size-20;
            }
            span {
                padding-bottom: 6px;
                border-bottom: solid 1px $primary;
            }
        }
        h2 {
            font-size: $font-size-24;
            line-height: 1.8em;
            font-weight: 600;
            color: $red;
            border-bottom: solid 1px $red;
            padding-bottom: 10px;
            margin-bottom: 20px;
            text-align: left !important;
            @include mq(sp) {
                font-size: $font-size-18;
            }
            span {
                padding-bottom: 6px;
                border-bottom: solid 1px $primary;
            }
        }
        h3 {
            font-size: $font-size-18;
            color: $main;
            font-weight: 600;
            margin-bottom: 20px;
            @include mq(sp) {
                font-size: $font-size-16;
            }
        }
        h4 {
            font-size: $font-size-16;
            color: $main;
            font-weight: 600;
            margin-bottom: 10px;
            @include mq(sp) {
                font-size: $font-size-14;
            }
        }
        h5 {
            font-size: $font-size-16;
            color: $main;
            font-weight: 600;
            margin-bottom: 10px;
            @include mq(sp) {
                font-size: $font-size-14;
            }
        }
        h6 {
            font-size: $font-size-16;
            color: $main;
            font-weight: 600;
            margin-bottom: 5px;
            @include mq(sp) {
                font-size: $font-size-14;
            }
        }
        a {
            color: $blue;
            &:hover {
                text-decoration: none;
            }
        }
        p {
            font-size: $font-size-16;
            line-height: 2.2em;
            //line-break: strict;
            word-break: break-word;
            @include mq(sp) {
                font-size: $font-size-14;
            }
            iframe {
                display: block;
                aspect-ratio: 500/450;
                width: 100%;
                // min-height: 450px;
            }
        }
        sup {
            font-size: $font-size-11;
            display: inline-block;
            vertical-align: top;
            position: relative;
            top: -0.2em;
        }
        sub {
            //font-size: 75%;
            font-size: $font-size-11;
            display: inline-block;
            vertical-align: bottom;
            position: relative;
            bottom: -0.2em;
        }
        p:empty {
            display: none;
        }
        p img {
            margin-top: 0px;
            height: auto;
            max-width: 100%;
            display: block;
            &.aligncenter {
                margin-left: auto;
                margin-right: auto;
            }
        }
        .wp-block-image {
            img {
                height: auto;
            }
        }
        .wp-caption {
            width: 100% !important;
            //padding-bottom: 30px;
            @include mq(sp) {
                //padding-bottom: 15px;
            }
            img {
                height: auto;
            }
            &.aligncenter {
                img {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
        .wp-caption-text {
            font-size: $font-size-13;
            color: #888888;
            display: block;
            line-height: 1.8em;
            margin-top: 10px;
        }
        .l-figure {
            &__outer {
                display: flex;
                justify-content: center;
            }
            figure {
                img {
                    max-width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                }
                figcaption {
                    margin-top: 15px;
                    font-size: $font-size-14;
                    @include mq(sp) {
                        font-size: $font-size-13;
                    }
                }
            }
        }
        .l-btns {
            &__outer {}
            .l-btn {
                width: 100%;
                max-width: 416px;
                min-height: 40px;
                height: auto;
                padding-top: 5px;
                padding-bottom: 5px;
                .ico_more {
                    //transform: rotate(180deg);
                    position: absolute;
                    right: 18px;
                }
            }
        }
        ul {
            margin: 0px;
            padding: 0;
            padding-left: 10px;
            margin-bottom: 40px;
            @include mq(sp) {
                margin-bottom: 30px;
            }
            li {
                list-style: none;
                padding-left: 15px;
                position: relative;
                font-size: $font-size-16;
                line-height: 1.7em;
                margin-bottom: 10px;
                @include mq(sp) {
                    font-size: $font-size-14;
                }
                &::before {
                    content: "";
                    display: block;
                    width: 7px;
                    height: 7px;
                    background-color: $red;
                    border-radius: 10px;
                    position: absolute;
                    left: 0px;
                    top: .7em;
                }
            }
        }
        ol {
            margin: 0px;
            padding: 0;
            counter-reset: number;
            margin-bottom: 40px;
            @include mq(sp) {
                margin-bottom: 30px;
            }
            li {
                list-style: none;
                counter-increment: number;
                position: relative;
                padding-left: 25px;
                font-size: $font-size-16;
                line-height: 1.7em;
                margin-bottom: 10px;
                @include mq(sp) {
                    font-size: $font-size-14;
                }
                &::before {
                    content: counter(number);
                    position: absolute;
                    left: 3px;
                    top: 0.5em;
                    width: 13px;
                    height: 13px;
                    padding-left: 2px;
                    padding-top: 2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    color: #fff;
                    color: $primary;
                    background-color: $primary;
                    background-color: $red;
                    font-size: $font-size-9;
                    font-weight: bold;
                    border-radius: 100px;
                    transform: scale(1);
                    @include mq(sp) {
                        font-size: $font-size-8;
                    }
                }
            }
        }
        table {
            border-top: solid 1px #ddd;
            border-left: solid 1px #ddd;
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
            tr {
                th {
                    background-color: #f9f9f9;
                    padding: .5em 1em;
                    font-size: $font-size-15;
                    border-bottom: solid 1px #ddd;
                    border-right: solid 1px #ddd;
                    //background-color: #eddfff;
                    line-height: 1.5em;
                    @include mq(sp) {
                        font-size: $font-size-14;
                    }
                }
                td {
                    padding: .5em 1em;
                    font-size: $font-size-15;
                    border-bottom: solid 1px #ddd;
                    border-right: solid 1px #ddd;
                    line-height: 1.5em;
                    @include mq(sp) {
                        font-size: $font-size-14;
                    }
                }
            }
        }
        .l-editer {
            display: flex;
            align-items: center;
            &__outer {}
            &__inner {
                padding-top: 0px;
                padding-bottom: 0px;
            }
            &-thumb {
                margin-right: 25px;
                img {
                    width: 150px;
                    height: 150px;
                    object-fit: cover;
                    object-position: center;
                    @include mq(sp) {
                        width: 110px;
                        height: 110px;
                    }
                }
            }
            &-body {
                p {
                    font-size: $font-size-14;
                    @include mq(sp) {
                        font-size: $font-size-13;
                    }
                }
            }
        }
        .l-iframe {
            &__outer {
                //aspect-ratio: 500/450;
                width: 100%;
                position: relative;
                padding-top: 56.4%;
                iframe {
                    display: block;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    /*p+.l-section-title__outer {
    margin-top: 60px;
    }
    p+.l-section-sub-title__outer {
    margin-top: 20px;
    }*/
    hr {
        border: none;
        padding: 0;
        margin: 0 0;
        border-bottom: solid 1px #000;
    }
    blockquote {
        padding-left: 2em;
        border-left: solid 1px #666;
        p {
            font-size: $font-size-14 !important;
            color: #666 !important;
            line-height: 1.9em;
            margin-top: 5px;
            @include mq(sp) {
                font-size: $font-size-13 !important;
            }
        }
    }
    /*p+.l-youtube-embed__outer {
margin-top: 40px;
@include mq(sp) {
margin-top: 20px;
}
}*/
    .l-youtube-embed {
        display: none;
        width: 100%;
        @media print {
            display: block;
            img {
                aspect-ratio: 3 / 2;
                object-fit: cover;
                object-position: center;
                width: 100%;
            }
        }
        &__outer {
            /*padding-bottom: 60px;
@include mq(sp) {
padding-bottom: 30px;
}*/
        }
        &__inner {
            iframe {
                aspect-ratio: 3 / 2;
                height: auto;
                width: 100%;
                @media print {
                    display: none;
                }
            }
        }
        &-caption {
            font-size: $font-size-13;
            color: #888888;
            display: block;
            line-height: 1.8em;
            margin-top: 5px;
        }
    }
    .l-notes {
        &__outer {}
        &__inner {
            padding: 1.0em 1.5em;
            border: solid 1px #666;
            p {
                font-size: $font-size-14;
                color: #666;
                line-height: 1.9em;
                margin-top: 5px;
                @include mq(sp) {
                    font-size: $font-size-13;
                }
            }
        }
    }
    .l-editer__inner {
        padding: 0;
        margin: 0;
    }
}

.l-pager {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 35px;
    padding-bottom: 110px;
    @include mq(sp) {
        padding-top: 25px;
        padding-bottom: 35px;
    }
    &__outer {}
    &__inner {
        display: flex;
        margin-left: -11px;
        margin-right: -12px;
        width: calc(100% + 23px);
        @include mq(sp) {
            flex-direction: column;
        }
    }
    &-prev {
        margin-left: 11px;
        margin-right: 12px;
        position: relative;
        text-decoration: none;
        width: calc((100% - 23px*2)/2);
        @include mq(sp) {
            width: calc((100% - 23px*1)/1);
            margin-bottom: 15px;
        }
        a {
            display: block;
            text-decoration: none;
            position: relative;
            padding: 30px 45px;
            box-sizing: border-box;
            background-color: #FFFFFF;
            border-radius: 30px;
            min-height: 185px;
            @include mq(sp) {
                padding: 20px 30px;
                border-radius: 20px;
                min-height: 1px;
            }
            .ico_more_arrow {
                position: absolute;
                left: -20px;
                top: 50%;
                transform: translateY(-50%) rotate(180deg);
                width: 40px;
                height: 40px;
                background-color: $green;
                @include mq(sp) {
                    width: 26px;
                    height: 26px;
                    left: -13px;
                    //top: 50%;
                }
            }
        }
        p {
            margin-bottom: 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            @include mq(sp) {
                font-size: $font-size-13;
                line-height: 1.7em;
            }
        }
        .l-pager {
            &-label {
                font-size: $font-size-24;
                color: $green;
                font-weight: $font-bold;
                font-family: HelveticaNowDisplay-Bd, 'HelveticaNowDisplay-Bd', sans-serif;
                margin-bottom: 15px;
                @include mq(sp) {
                    font-size: $font-size-18;
                    margin-bottom: 7px;
                }
            }
        }
    }
    &-next {
        margin-left: 11px;
        margin-right: 12px;
        position: relative;
        text-decoration: none;
        width: calc((100% - 23px*2)/2);
        @include mq(sp) {
            width: calc((100% - 23px*1)/1);
        }
        a {
            display: block;
            text-decoration: none;
            position: relative;
            padding: 30px 45px;
            box-sizing: border-box;
            background-color: #FFFFFF;
            border-radius: 30px;
            min-height: 185px;
            @include mq(sp) {
                border-radius: 20px;
                padding: 20px 30px;
                min-height: 1px;
            }
            .ico_more_arrow {
                position: absolute;
                left: auto;
                right: -20px;
                top: 50%;
                transform: translateY(-50%);
                background-color: $yellow;
                @include mq(sp) {
                    width: 26px;
                    height: 26px;
                    right: -13px;
                }
            }
        }
        p {
            margin-bottom: 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            @include mq(sp) {
                font-size: $font-size-13;
                line-height: 1.7em;
            }
        }
        .l-pager {
            &-label {
                font-size: $font-size-24;
                color: $yellow;
                font-weight: $font-bold;
                font-family: HelveticaNowDisplay-Bd, 'HelveticaNowDisplay-Bd', sans-serif;
                margin-bottom: 15px;
                @include mq(sp) {
                    font-size: $font-size-18;
                    margin-bottom: 7px;
                }
            }
        }
    }
}

.l-back {
    &__outer {}
    &__inner {
        padding-bottom: 180px;
        @include mq(sp) {
            padding-bottom: 90px;
        }
    }
}

.l-morebtn {
    color: $blue;
    font-size: $font-size-24;
    font-weight: $font-bold;
    text-decoration: none;
    position: relative;
    padding-left: 45px;
    letter-spacing: 0.1em;
    @include mq(sp) {
        font-size: $font-size-18;
        padding-left: 35px;
    }
    .ico_more_arrow {
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        background-color: $blue;
        @include mq(sp) {
            top: calc(50% - 2px);
        }
    }
    &:hover {
        color: $blue;
        .ico_more_arrow {
            transform: translateY(-50%) scale(1.1);
            svg {
                position: relative;
                left: 0px;
            }
        }
    }
    &__outer {
        padding-top: 16px;
        @include mq(sp) {
            padding-bottom: 0px;
        }
    }
    &__inner {
        display: flex;
        justify-content: flex-end;
    }
}

.g-mordal {
    &-base {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 4900;
        display: block;
        backdrop-filter: blur(12px);
        display: none;
        opacity: 0;
    }
    &-wrapper {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 9000;
        display: none;
        padding: 0px;
        box-sizing: border-box;
        overflow: auto;
        align-items: center;
        justify-content: center;
        @include mq(sp) {
            padding: 0px;
        }
    }
    &-close {
        width: 62px;
        height: 62px;
        display: block;
        position: absolute;
        right: -10px;
        top: 20px;
        @include mq(sp) {
            width: 42px;
            height: 42px;
        }
        &:before {
            content: "";
            display: block;
            width: 62px;
            height: 62px;
            @include mq(sp) {
                width: 42px;
                height: 42px;
            }
            background-image: url('../img/common/ico_mordal_close.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            @include mq(sp) {
                width: 42px;
                height: 42px;
            }
        }
        &:hover {
            transform: scale(1.05);
        }
    }
    &-content {
        width: 100%;
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        display: block;
        pointer-events: all;
        &__outer {
            position: relative;
            z-index: 4905;
            max-width: 1300px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 50px;
            margin-bottom: 50px;
            pointer-events: none;
            @include mq(sp) {
                max-width: 428px;
                width: 100%;
            }
            @include mq(minsp) {
                height: 248px;
            }
        }
        &__inner {
            width: 100%;
            text-align: center;
            position: relative;
            z-index: 12;
            padding: 0px;
            box-sizing: border-box;
            padding: 30px 60px;
            padding-bottom: 60px;
            background-color: $green;
            border-radius: 30px;
            @include mq(sp) {
                padding: 15px 30px;
                padding-bottom: 30px;
            }
        }
        &-header {
            text-align: left;
            padding-bottom: 30px;
            position: relative;
            h2 {
                font-size: $font-size-64;
                color: $primary;
                font-family: HelveticaNowDisplay-Bd, 'HelveticaNowDisplay-Bd', sans-serif;
                span {
                    color: $yellow;
                }
                font-weight: 600;
                @include mq(sp) {
                    padding-top: 18px;
                    font-size: $font-size-32;
                }
                @include mq(minsp) {
                    font-size: $font-size-29;
                }
            }
        }
        &-body {
            text-align: left;
            p {
                font-size: $font-size-20;
                color: #000;
                //font-weight: 600;
                //margin-bottom: 5px;
                @include mq(sp) {
                    font-size: $font-size-15;
                    br {
                        display: none;
                    }
                }
            }
            h2 {
                font-size: $font-size-20;
                color: #000;
                font-weight: 600;
                font-family: HelveticaNowDisplay-Bd, 'HelveticaNowDisplay-Bd', sans-serif;
                @include mq(sp) {
                    font-size: $font-size-15;
                }
            }
            .l-btns {
                padding-top: 25px;
                .l-btn {
                    max-width: 290px;
                    width: 100%;
                    height: 40px;
                    font-size: $font-size-15;
                    @include mq(minsp) {
                        font-size: $font-size-15;
                    }
                    @include mq(minsp) {}
                    &.l-no {
                        margin-top: 13px;
                        background-color: #D6EAE1;
                        color: #000;
                    }
                }
                font-weight: 600;
            }
            .l-high-items {
                counter-reset: number;
                .l-high-item {
                    position: relative;
                    counter-increment: number;
                    text-align: left;
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 40px;
                    &-text {
                        width: calc(100% - 190px);
                        max-width: 900px;
                        padding-top: 25px;
                        @include mq(sp) {
                            width: calc(100% - 75px);
                            padding-top: 0px;
                        }
                    }
                    h3 {
                        font-size: $font-size-32;
                        color: $yellow;
                        margin-bottom: 15px;
                        font-weight: $font-bold;
                        @include mq(sp) {
                            padding-top: 0px;
                            font-size: $font-size-24;
                            margin-bottom: 5px;
                        }
                        @include mq(minsp) {
                            font-size: $font-size-22;
                        }
                        &.en {
                            font-size: $font-size-48;
                            font-family: HelveticaNowDisplay-Bd, 'HelveticaNowDisplay-Bd', sans-serif;
                            @include mq(sp) {
                                font-size: $font-size-24;
                            }
                            @include mq(minsp) {
                                font-size: $font-size-22;
                            }
                        }
                        span {
                            margin-left: 15px;
                            display: inline-block;
                            font-size: $font-size-24;
                            color: $primary;
                            font-weight: $font-normal;
                            font-family: HelveticaNowDisplay-Bd, 'HelveticaNowDisplay-Bd', sans-serif;
                            @include mq(sp) {
                                margin-left: 0px;
                                font-size: $font-size-16;
                                line-height: 1.4em;
                                display: block;
                            }
                            @include mq(minsp) {
                                display: block;
                            }
                        }
                    }
                    p {
                        font-size: $font-size-20;
                        line-height: 1.9em;
                        @include mq(sp) {
                            font-size: $font-size-18;
                            //margin-left: -100px;
                            //width: calc(100% + 100px);
                        }
                    }
                    &::before {
                        content: counter(number);
                        display: inline-block;
                        width: 190px;
                        //position: absolute;
                        //left: 0px;
                        //top: 0.1em;
                        //width: 22px;
                        //height: 22px;
                        //padding-left: 0px;
                        //padding-top: 0px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        //text-align: center;
                        //color: #fff;
                        color: $primary;
                        //background-color: $primary;
                        //background-color: #eddfff;
                        font-size: $font-size-110;
                        line-height: 1.1em;
                        font-weight: bold;
                        //border-radius: 100px;
                        //transform: scale(1);
                        font-family: HelveticaNowDisplay-Bd, 'HelveticaNowDisplay-Bd', sans-serif;
                        @include mq(sp) {
                            font-size: $font-size-55;
                            width: 75px;
                            align-items: flex-start;
                        }
                    }
                }
            }
        }
    }
}

.l-select {
    &__outer {
        margin-left: auto;
        margin-bottom: 10px;
        margin-right: 0px;
        @include mq(sp) {
            margin-bottom: 5px;
        }
    }
    &__inner {
        position: relative;
        select {
            border: none;
            padding: 10px 20px;
            padding-left: 30px;
            padding-right: 45px;
            font-size: $font-size-16;
            font-weight: 600;
            color: $main;
            border-radius: 100px;
            //border: solid 1px #DBDBDB;
            width: 100%;
            //border-radius: 0;
            appearance: none;
            font-family: "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "Hiragino Sans", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
            @include mq(sp) {
                font-size: $font-size-13;
            }
            &::-ms-expand {
                /* select要素のデザインを無効にする（IE用） */
                display: none;
            }
            option {
                font-family: "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "Hiragino Sans", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
            }
        }
        &::after {
            content: "";
            height: 0px;
            width: 0px;
            border: solid 12px $blue;
            border-left: solid 7px rgba(0, 0, 0, 0);
            border-right: solid 7px rgba(0, 0, 0, 0);
            border-bottom: solid 12px rgba(0, 0, 0, 0);
            position: absolute;
            right: 16px;
            top: 14px;
            //transform: translateY(-50%);
            pointer-events: none;
            @include mq(sp) {
                top: 12px;
            }
        }
    }
}

.l-card-list {
    &__outer {}
    &__inner {
        display: flex;
        flex-wrap: wrap;
        margin-left: -5px;
        margin-right: -5px;
        width: calc(100% + 10px);
    }
}

.l-card {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 20px;
    width: calc((100% - 10px *3)/3);
    border-radius: 30px;
    background-color: #fff;
    overflow: hidden;
    position: relative;
    &.l-impact-note-card {
        min-height: 490px;
        @include mq(sp) {
            min-height: 1px;
            padding-bottom: 70px;
        }
        .l-card {
            position: relative;
            padding-bottom: 90px;
            &-thumb {
                max-width: 100%;
                border: none;
                //border: solid 1px #d9d9d9;
                border-radius: 0px;
                box-sizing: border-box;
                overflow: hidden;
                position: relative;
                z-index: 4;
                img {
                    //border: solid 1px #d9d9d9;
                    border-radius: 0px;
                    box-sizing: border-box;
                    aspect-ratio: 16/9;
                    object-position: center;
                }
            }
            &-body {
                padding-bottom: 15px;
                h3 {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 4;
                }
            }
            &-footer {
                position: absolute;
                left: 0px;
                bottom: 0px;
                width: 100%;
                box-sizing: border-box;
                .ico_blank {
                    margin-left: auto;
                    margin-bottom: 20px;
                }
            }
        }
    }
    >a {
        display: block;
        position: absolute;
        left: 0px;
        top: 0px;
        height: 100%;
        width: 100%;
        z-index: 10;
        &:hover {
            background-color: rgba(255, 255, 255, 0.5);
        }
    }
    &-thumb {
        display: inline-block;
        position: relative;
        .ico_new {
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: 100;
        }
        img {
            aspect-ratio: 4/3;
            width: 100%;
            border-radius: 30px 30px 0px 0;
            object-fit: cover;
            object-position: center;
            position: relative;
            z-index: 10;
        }
    }
    &-body {
        padding: 25px;
        padding-bottom: 15px;
        min-height: 150px;
        box-sizing: border-box;
        h3 {
            font-size: $font-size-20;
            line-height: 1.8em;
            font-weight: 600;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
        }
    }
    &-footer {
        padding: 20px 25px;
        padding-top: 0px;
        //display: flex;
        @include mq(sp) {
            padding-bottom: 15px;
        }
        .l-cat {
            margin: 0;
            padding: 0;
            padding-bottom: 10px;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            li {
                margin-right: 14px;
                margin-bottom: 13px;
                @include mq(sp) {
                    margin-right: 9px;
                    margin-bottom: 9px;
                }
                a {
                    display: flex;
                    align-items: center;
                    padding: 4px 15px;
                    border-radius: 100px;
                    background-color: $other;
                    color: $main;
                    font-size: $font-size-12;
                    line-height: 1.1em;
                    font-weight: 600;
                    white-space: nowrap;
                    text-align: center;
                    text-decoration: none;
                    min-height: 28px;
                    box-sizing: border-box;
                    line-height: 1.1em;
                    @include mq(sp) {
                        padding: 2px 15px;
                        font-size: $font-size-10;
                        min-height: 21px;
                    }
                    &.c-yellow {
                        background-color: $yellow;
                        color: $main;
                    }
                    &.c-blue {
                        background-color: $blue;
                        color: $primary;
                    }
                    &.c-red {
                        background-color: $red;
                        color: $primary;
                    }
                    &.c-green {
                        background-color: $green;
                        color: $primary;
                    }
                }
            }
        }
        .l-date {
            font-size: $font-size-16;
            margin-left: auto;
            text-align: right;
            display: block;
            color: #646464;
            font-weight: 500;
            @include mq(sp) {
                font-size: $font-size-13;
            }
        }
    }
}

.l-switch-news {
    &__outer {
        padding-bottom: 85px;
        @include mq(sp) {
            padding-bottom: 59px;
        }
    }
    &__inner {
        max-width: 520px;
        margin-left: auto;
        margin-right: auto;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            border-radius: 100px;
            background-color: #D2E6FF;
            position: relative;
            overflow: hidden;
            li {
                width: 50%;
                margin: 0;
                padding: 0;
                overflow: hidden;
                &:first-child {
                    a {
                        &:before {
                            transform: translateX(260px);
                            @include mq(sp) {
                                transform: translateX(180px);
                            }
                        }
                    }
                }
                &:last-child {
                    a {
                        &:before {
                            transform: translateX(-260px);
                            @include mq(sp) {
                                transform: translateX(-180px);
                            }
                        }
                    }
                }
                a {
                    //background-color: #D2E6FF;
                    padding: 13px;
                    font-size: $font-size-20;
                    font-weight: $font-bold;
                    min-height: 63px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    width: 100%;
                    justify-content: center;
                    text-decoration: none;
                    color: #2170D8;
                    border-radius: 100px;
                    position: relative;
                    white-space: nowrap;
                    opacity: 1;
                    line-height: 1.1em;
                    @include mq(sp) {
                        font-size: $font-size-13;
                        min-height: 40px;
                        //line-height: 1.1em;
                    }
                    &:hover {
                        opacity: 1;
                        &:before {
                            opacity: 1;
                        }
                    }
                    //transform: translateX(-260px);
                    &:before {
                        content: "";
                        display: block;
                        width: 260px;
                        height: 100%;
                        background-color: #2170D8;
                        position: absolute;
                        left: 0px;
                        top: 0px;
                        border-radius: 100px;
                        transition: 0.22s cubic-bezier(0.44, 0.18, 0.49, 0.99);
                        transform: translateX(260px);
                        @include mq(sp) {
                            width: 100%;
                            transform: translateX(100%);
                        }
                        //opacity: 1;
                    }
                    span {
                        position: relative;
                        z-index: 10;
                    }
                    &:last-child,
                    &:first-child {
                        //&:hover,
                        &.select {
                            color: #fff;
                            opacity: 1;
                            &:before {
                                transform: translateX(0px);
                                @include mq(sp) {
                                    transform: translateX(0px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.l-sort-category {
    @include mq(sp) {
        width: calc(1000px);
    }
    &__outer {
        padding-bottom: 60px;
        margin-left: 0px;
        margin-right: 0px;
        width: calc(100% + 0px);
        @include mq(sp) {
            margin-right: -25px;
            width: calc(100% + 25px);
            overflow: auto;
            padding-bottom: 1px;
            margin-bottom: 55px;
        }
    }
    &__inner {
        margin-left: -5px;
        margin-right: -5px;
        width: calc(100% + 10px);
        @include mq(sp) {
            margin: 0px;
        }
        ul {
            margin: 0;
            padding: 0;
            padding-bottom: 10px;
            list-style: none;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-left: -9px;
            margin-right: -9px;
            width: calc(100% + 18px);
            @include mq(sp) {
                display: inline-block;
                white-space: nowrap;
                margin: 0px;
            }
            li {
                margin: 0;
                margin-right: 9px;
                margin-left: 9px;
                @include mq(sp) {
                    display: inline-block;
                    white-space: nowrap;
                    margin: 0px;
                    margin-right: 9px;
                }
                a {
                    display: flex;
                    align-items: center;
                    padding: 4px 35px;
                    border-radius: 100px;
                    background-color: $other;
                    color: $main;
                    font-size: $font-size-18;
                    line-height: 1.1em;
                    font-weight: 600;
                    white-space: nowrap;
                    text-align: center;
                    text-decoration: none;
                    min-height: 37px;
                    box-sizing: border-box;
                    @include mq(sp) {
                        font-size: $font-size-13;
                        min-height: 27px;
                    }
                    &.c-yellow {
                        background-color: $yellow;
                        color: $main;
                    }
                    &.c-blue {
                        background-color: $blue;
                        color: $primary;
                    }
                    &.c-red {
                        background-color: $red;
                        color: $primary;
                    }
                    &.c-green {
                        background-color: $green;
                        color: $primary;
                    }
                }
            }
        }
    }
}

.l-list {
    &__outer {
        background-color: #FFFFFF;
        border-radius: 42px;
        padding: 50px;
        padding-bottom: 90px;
        margin-bottom: 120px;
        @include mq(sp) {
            padding: 0;
            border-radius: 0px;
            background-color: transparent;
        }
    }
    &__inner {
        .l-card {
            display: flex;
            margin-left: 5px;
            margin-right: 5px;
            margin-bottom: 25px;
            width: calc((100% - 10px* 1) / 1);
            border-radius: 0px;
            background-color: #fff;
            overflow: hidden;
            position: relative;
            border-bottom: solid 1px #D3D3D3;
            padding: 0px 15px 25px;
            box-sizing: border-box;
            align-items: flex-start;
            @include mq(sp) {
                padding: 20px 0;
                background-color: transparent;
                margin-bottom: 0;
                &:first-child {
                    border-top: solid 1px #D3D3D3;
                }
            }
            &-thumb {
                max-width: 290px;
                //border: solid 1px #d9d9d9;
                //border-radius: 30px;
                box-sizing: border-box;
                overflow: hidden;
                border-radius: 10px;
                aspect-ratio: 290/172;
                border: solid 1px #d9d9d9;
                @include mq(sp) {
                    max-width: 145px;
                    border: solid 1px #d9d9d9;
                }
                img {
                    //border: solid 1px #d9d9d9;
                    border-radius: 0px;
                    box-sizing: border-box;
                    aspect-ratio: 290/172;
                    object-position: center;
                    //border: solid 1px #d9d9d9;
                    border-radius: 0px;
                    box-sizing: border-box;
                    @include mq(sp) {
                        border-radius: 0px;
                        border: none;
                    }
                }
            }
            &-body {
                max-width: calc(100% - 290px);
                padding-left: 58px;
                box-sizing: border-box;
                @include mq(sp) {
                    padding: 0px;
                    max-width: calc(100% - 145px);
                    padding-left: 14px;
                    min-height: 1px;
                    //max-width: 145px;
                }
                h3 {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    @include mq(sp) {
                        font-size: $font-size-12;
                    }
                }
                .l-meta {
                    padding-bottom: 20px;
                    @include mq(sp) {
                        padding-bottom: 6px;
                    }
                    .l-date {
                        font-size: $font-size-16;
                        margin-left: auto;
                        text-align: left;
                        display: block;
                        color: #646464;
                        font-weight: 500;
                        @include mq(sp) {
                            font-size: $font-size-10;
                            line-height: 1.4em;
                        }
                    }
                }
            }
        }
    }
}

.l-images {
    img {
        border-radius: 30px;
    }
    &__outer {
        padding-bottom: 60px;
    }
    &__inner {}
}

.l-figure {
    &__outer {
        display: flex;
        justify-content: center;
    }
    figure {
        img {
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
            display: block;
        }
        figcaption {
            margin-top: 15px;
            font-size: $font-size-14;
            @include mq(sp) {
                font-size: $font-size-13;
            }
        }
    }
}